/* eslint-disable no-param-reassign */
/* eslint-disable max-depth */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-lines */
/* eslint-disable  no-shadow */
/* eslint-disable no-extra-parens */

import { apiCall } from "@/middleware/api"
import { source, types } from "@/types"
import { constants } from "@/utils/global"
import queryString from "query-string"
import { sorter } from ".."
import { constructProduct } from "../products"
import { scrollToTop } from "@/hooks/utils/useScrollTop"
import { encodeHashJS } from "@/middleware/api/secure"

const constructOrderFilter = (filters, categories, brands) => {
  const category = categories || []
  const brand = brands || []
  let pvp = []
  let order_pvp = null
  const attr_product = []
  const attr_variant = []
  const word = []
  const meta = {}
  const sede = ``
  for (const item of filters) {
    switch (item.type) {
    case types.catalog.brand:
      brand.push(item.parentId)
      break
    case types.catalog.product:
      switch (item.parent) {
      case types.catalog.variant:
        attr_variant.push(item.childId)
        break
      default:
        attr_product.push(item.childId)
        break
      }
      break
    case types.catalog.order.order:
      order_pvp = item.childId
      break
    case types.catalog.priceRange:
      pvp = [ item.price.min, item.price.max ]
      break
    case types.catalog.meta:
      const [ name, value ] = item.childId
      switch (name) {
      case `mbn`:
        meta.brand = value
        break
      case `mm`:
        meta.model = value
        break
      case `my`:
        meta.year = value
        break
      default:
        break
      }
      break
    default:
      break
    }
  }

  return {
    filters: {
      category,
      brand,
      pvp,
      order_pvp,
      attr_product,
      attr_variant,
      word,
      sede,
      meta
    }
  }
}
const constructELKcatalogue = (
  filters, categories, brands, limit, page, platformId, languageId, meta, children, search, searchText, abbrElastic
) => {
  const tmpSearch = search ? searchText?.split(` `) : []
  const sort = search ? [
    { _score: { order: `desc` }},
    { sequence: { order: `desc` }},
  ] : []
  const filter = [{ term: { platform_id: platformId }}, { term: { language_id: languageId }}, { term: { is_deleted: 0 }}]
  let should
  let must
  let queryString
  if (!search) {
    should = !meta ? categories?.length > 0 ? categories.map(x => ({ match: { categories: x }})) : [] : children.map(x => ({ match: { categories: x }}))
    must = brands?.length > 0 ? brands.map(x => ({
      query_string: {
        default_field: `brand_id`,
        query: x?.toString()
      }
    })) : []
    queryString = {
      query: ``,
      fields: [ `meta_moto.cc`, `meta_moto.year` ]
    }
  } else {
    const multi_match = {
      query: tmpSearch.map(x => {
        if (x.match(/^[a-zA-Z0-9]+$/)) {
          return `*${x}*`
        }
        const replaced = x.replace(/[-[\]{}()*+?.,\\^$|#\s/]/g, `\\$&`)
        return replaced
      }).join(` `),
      fields: [
        `webdata.title^2`,
        `webdata.h1^2`,
        `webdata.h2^0.1`,
        `attributes.attr_name^1.5`,
        `webdata.prefix^1.2`,
        `variant.brand_name^2`,
        `variant.ean^2`,
        `webdata.dimensions^1.5`,
        `variant.reference^1`,
        `breadcrumbs.h1^0.3`,
        `breadcrumbs.path^0.3`,
        `meta_moto.meta^0.5`,
      ],
      minimum_should_match: tmpSearch?.length,
      fuzziness: `auto`,
      fuzzy_prefix_length: 1,
      default_operator: `or`,
      analyze_wildcard: true,
      analyzer: `lowercase_analyzer`
    }
    must = searchText.trim() !== `` ? [{ query_string: multi_match }] : []
  }
  filters.forEach(x => {
    switch (x.type) {
    case types.catalog.order.order:
      switch (x.childId) {
      case types.catalog.order.new:
        sort.push({ news: { order: types.catalog.order.desc }})
        sort.push({ sequence: { order: `desc` }})
        break
      default:
        sort.push({ sort: { order: x.childId }})
      }
      break
    case types.catalog.priceRange:
      filter.push({
        range: {
          sort: {
            gte: x.price.min,
            lte: x.price.max
          }
        }
      })
      break
    case types.catalog.product:
      if (x.model === null) {
        filter.push({ term: { attr: x.childId }})
      } else if (meta) {
        must.push({ term: { "meta_moto.meta.keyword": `${queryString.query};${x.model.model};${x.model.cc};${x.model.year}` }})
      }
      break
    case types.catalog.brand:
      if (!meta) {
        queryString.default_field = `brand_id`
        queryString.query = queryString.query === `` ? x.parentId.toString() : `${queryString.query} OR ${x.parentId}`
      } else {
        queryString.default_field = `meta_moto.meta`
        queryString.query = queryString.query === `` ? x.brandName : `${queryString.query} AND ${x.brandName}`
      }
      if (queryString.query !== ``) {
        const { fields, ...queryStrings } = queryString
        must.pop()
        must.push({ query_string: queryStrings })
      }
      break
    case types.catalog.category:
      if (!search) {
        queryString.default_field = `categories`
        queryString.query = queryString.query === `` ? x.parentId.toString() : `${queryString.query} OR ${x.parentId}`
        if (queryString.query !== ``) {
          const { fields, ...queryStrings } = queryString
          must.pop()
          must.push({ query_string: queryStrings })
        }
      } else {
        filter.push({ term: { categories: x.parentId.toString() }})
      }
      break
    default:
      break
    }
  })
  if (sort?.length === 0) {
    sort.push({ sequence: { order: `desc` }})
  }
  if (meta) {
    must.push({ exists: { field: `meta_moto.meta` }})
  }

  const minimum_should_match = search ? tmpSearch?.length > 1 ? tmpSearch?.length : 1 : !meta && should?.length > 0 ? 1 : 0

  if ((meta && filters.filter(x => x !== false || x.type === types.catalog.brand || x.model !== null)?.length > 2) || !meta) {
    return {
      index: `${abbrElastic}_product_elk`,
      from: (page === 1 ? 0 : page - 1) * limit,
      size: limit,
      sort,
      _source: source.productCard,
      query: search ? {
        function_score: {
          boost: 20,
          max_boost: 100,
          score_mode: `multiply`,
          query: {
            bool: {
              must,
              filter
            }
          }
        }
      } : {
        bool: {
          must,
          minimum_should_match,
          should,
          filter
        }
      },
      aggs: {
        count: { value_count: { field: `product_id` }},
        attrs: {
          terms: {
            field: `attr`,
            size: 10000
          }
        },
        brands: {
          terms: {
            field: `brand_id`,
            size: 10000
          }
        },
        categories: {
          terms: {
            field: `categories`,
            size: 10000
          }
        },
        maxPrice: { max: { field: `sort` }}
      }
    }
  }
  return false
}
export const constructCatalogue = async(
  props, children, meta, motoVO, search, searchText, abbrElastic
) => {
  const {
    page, limit, platform, language, filters, categories, brand, clientId
  } = props
  const orderFilter = motoVO ? await constructOrderFilter(filters, categories, brand) : await constructELKcatalogue(
    filters, categories, brand, limit, page, platform, language, meta, children?.map(x => x.info.id), search, searchText, abbrElastic
  )

  const encrypt = motoVO ? { filters: orderFilter.filters, abbrElastic } :
    { slug: orderFilter, platformId: platform, languageId: language, clientId, abbrElastic }

  if (!motoVO) {
    if (orderFilter !== false) {
      return encrypt
    }
    return 0
  }
  return encrypt
}

export const constructPath = async(
  params, newFilter, cleanPath, router, page = false
) => {
  scrollToTop()
  !page && delete params.p
  const search = await queryString.stringify(params, {
    arrayFormat: `bracket-separator`,
    arrayFormatSeparator: `|`
  })
  const arr = await sorter(newFilter.filter(x => x.parent !== types.catalog.get),
    `type`,
    `asc`).filter(x => x !== false)
  let path = cleanPath
  await arr.map(x => {
    path = `${path}${x?.pathParams?.join(`-`)}/`
    return false
  })

  await router.push((`${path}${search ? `?${search}` : ``}`))
}

export const startFilterMeta = async(handle, init, restart) => {
  await restart()
  const params = await queryString.parse(globalThis.window.location.search, {
    arrayFormat: `bracket-separator`,
    arrayFormatSeparator: `|`
  })
  const tmpOpt = Object.entries(params).map(x => {
    return {
      ...constants.OBJ_FILTER,
      type: types.catalog.meta,
      parent: types.catalog.get,
      childId: x,
      pathParams: [ `GET`, x[0], x[1] ],
    }
  })
  await init({
    id: null,
    cleanPath: globalThis.window.location.pathname,
    category: true,
    breadCrumbs: [],
    info: {},
    children: [],
    pathFilter: tmpOpt,
    filters: [],
  })
  return tmpOpt
}

export const getPathFilter = (
  searchParams, filterParams, brand, lang, fill = [], list = []
) => {
  let page = 1
  const tmpParams = Object.entries(searchParams).map(x => {
    switch (x[0]) {
    case `r[]`:
      const range = x[1].split(`|`)
      return {
        ...constants.OBJ_FILTER,
        type: types.catalog.priceRange,
        parent: types.catalog.get,
        pathParams: [ `GET`, `r`, range ],
        price: {
          min: range[0],
          max: range[1],
        },
      }
    case `o`:
      return {
        ...constants.OBJ_FILTER,
        type: types.catalog.order.order,
        childId: x[1],
        parent: types.catalog.get,
        pathParams: [ `GET`, `o`, x[1] ],
      }
    case `p`:
      page = parseInt(x[1], 10)
      return {
        ...constants.OBJ_FILTER,
        type: types.catalog.page,
        parent: types.catalog.get,
        pathParams: [ `GET`, `p`, parseInt(x[1], 10) ],
        page: parseInt(x[1], 10),
      }
    case `mbn`:
    case `mb`:
    case `my`:
    case `mm`:
      return {
        ...constants.OBJ_FILTER,
        type: types.catalog.meta,
        parent: types.catalog.get,
        childId: x,
        pathParams: [ `GET`, x[0], x[1] ],
      }

    default:
      return false
    }
  })
  const tmpArr = filterParams?.map((x, i) => {
    let tmp
    let tmpSearch
    let child
    let splitter
    if (x?.length > 0) {
      switch (true) {
      case x.includes(`-`):
        splitter = x?.split(`-`)
        tmp = fill.find(y => y.translations[lang].toLowerCase() === decodeURI(splitter[0]))
        if (tmp) {
          let tmpSpliter = ``
          if (splitter[0] !== `search` && splitter?.length > 2) {
            splitter.forEach((x, i) => {
              if (i === 1) {
                tmpSpliter = splitter[i]
              } else if (i > 1) {
                tmpSpliter = `${tmpSpliter}-${splitter[i]}`
              }
            })
          } else {
            tmpSpliter = splitter[1]
          }
          child = tmp.attributes.find(z => JSON.parse(z.translations)[lang].toLowerCase() === decodeURI(tmpSpliter))
          if (child) {
            return {
              ...constants.OBJ_FILTER,
              type: types.catalog.product,
              parent: tmp.parent,
              parentId: tmp.attribute_id,
              childId: child?.id || null,
              pathParams: [`${tmp.translations[lang]}-${JSON.parse(child.translations)[lang]}`.toLowerCase()],
            }
          }
        } else if (splitter[0] === `search`) {
          tmpSearch = fill.find(y => y.attribute_id === 30)
          if (tmpSearch) {
            child = tmpSearch.attributes.find(z => z.model.toLowerCase() === decodeURI(splitter[1]) && z.cc.toString() === decodeURI(splitter[2]) && z.year.toString() === decodeURI(splitter[3]))
            if (child) {
              return {
                ...constants.OBJ_FILTER,
                type: types.catalog.product,
                parent: tmpSearch.translations[lang?.toLowerCase()],
                parentId: tmpSearch.attribute_id,
                childId: child?.id || null,
                model_id: child?.model_id,
                model: {
                  model: child.model,
                  cc: child.cc,
                  year: child.year
                },
                pathParams: [`search-${child.model}-${child.cc}-${child?.year}`.toLowerCase()],
              }
            }
          }
        } else if (splitter[0] === types.catalog.category) {
          let tmpSpliter = ``
          if (splitter?.length > 2) {
            splitter.forEach((x, i) => {
              if (i === 1) {
                tmpSpliter = splitter[i]
              } else if (i > 1) {
                tmpSpliter = `${tmpSpliter}-${splitter[i]}`
              }
            })
          } else {
            tmpSpliter = splitter[1]
          }

          child = list.find(z => z.info.url.toLowerCase() === tmpSpliter)
          if (child) {
            return {
              ...constants.OBJ_FILTER,
              type: types.catalog.category,
              parentId: child.info.id,
              pathParams: [`${types.catalog.category}-${child.info?.url}`],
            }
          }
        } else {
          tmp = brand?.find(y => y.webdata.url === x)
          if (tmp) {
            return {
              ...constants.OBJ_FILTER,
              type: types.catalog.brand,
              parentId: tmp.brand_id,
              brandName: tmp.name,
              pathParams: [tmp.webdata.url.toLowerCase()],
            }
          }
        }
        break

      default:
        tmp = brand?.find(y => y.webdata.url === x)
        if (tmp) {
          return {
            ...constants.OBJ_FILTER,
            type: types.catalog.brand,
            parentId: tmp.brand_id,
            brandName: tmp.name,
            pathParams: [tmp.webdata.url.toLowerCase()],
          }
        }
        break
      }
    }
    return false
  })
  return { pathFilters: [ tmpArr, tmpParams ].flat(), page }
}

export const getMotoVOList = async(orderParams, encrypt) => {
  const { page, limit, language, platform, motoVO, filter } = orderParams
  const sendData = {
    url: `${constants.URL.BIKE_CATALOG}${page}/${limit}/${language}/${platform}`,
    encrypt,
    type: `POST`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  const returnList = {
    ...message,
    catalogue: constructProduct(message.catalog, motoVO).items,
    result: message.count,
    urlResults: message.count,
    maxPrice: message.maxPrice,
    categories: [...new Set(message.catalog.map(x => x.product_categories).flat())],
    motovo: motoVO,
    filters: filter,
  }
  return returnList
}
