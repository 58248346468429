export const paper = {
  paperLang: `paperLang`,
  paperPopper: `paperPopper`,
  paperRating: `paperRating`,
  paperSlider: `paperSlider`,
  whislist: `whislist`,
  couponList: `couponList`,
  brandCard: `brandCard`,
  banner404Img: `banner404Img`,
  paperBody: `paperBody`,
  paperSumaryBox: `paperSumaryBox`,
  paperBoxTitle: `paperBoxTitle`,
  paperBodyBox: `paperBodyBox`,
  paperBottomBox: `paperBottomBox`,
  payIcon: `payIcon`,
}
