import { apiCall } from "@/middleware/api"
import { constants } from "@/utils/global"
import { constructProduct } from "."
import { constructRelations } from "./relationsHelpers"
import { encodeHashJS } from "@/middleware/api/secure"

export const getRelation = async prop => {
  const encrypt = await constructRelations(prop)
  const sendData = {
    url: `${constants.URL.CAT_RELATIONS}`,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const result = await apiCall(encriptedData)
  const response = result?.message ? await constructProduct(result.message.items) : []
  const { items } = await response
  return items
}
