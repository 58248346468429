export const chip = {
  chipLabel: `chipLabel`,
  chipMenu: `chipMenu`,
  chipCat: `chipCat`,
  formChip: `formChip`,
  accChip: `accChip`,
  historyChip: `historyChip`,
  boxChips: `boxChips`,
  chipRes: `chipRes`,
  chipPanelBuy: `chipPanelBuy`,
  chipText: `chipText`,
  chipLink: `chipLink`,
}
