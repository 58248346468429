import { getInitServer } from "@/config/actions/initActions"
import { getPageData } from "@/helpers/routeSelection"
import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { config } from "@/middleware/config/config"
import { cache } from "react"
import { deviceType, languageDomainHelper } from "."
import { constants } from "../global"

export const serverInit = async req => {
  const UA = await req.headers[`user-agent`]
  const { host } = req.headers
  const domainHost = !host.includes(`localhost`) ? host?.split(`.`)[host?.split(`.`)?.length - 1] : `com`
  const isMobile = await deviceType(UA)
  const info = await req.cookies[config.COOKIES._WLYR_] ? req.cookies[config.COOKIES._WLYR_] : false

  // let hostname
  // if (info) {
  //   hostname = await JSON.parse(info)
  // } else {
  const sendData = {
    url: `${constants.URL.INIT}${domainHost}`,
    encrypt: ``,
    type: `GET`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { message } = await apiCall(encriptedData)
  const hostname = message
  hostname.lang = languageDomainHelper(hostname?.domain)

  // }
  const {
    events, id: platformId, domain, lang
  } = await hostname
  return {
    platform: {
      events,
      platformId,
      domain,
      codeLang: lang
    },
    isMobile,
    hostname,
    domainHost
  }
}

export const apiConstruct = props => {
  const myHeaders = new Headers()
  myHeaders.append(`Content-Type`, `application/x-www-form-urlencoded`)

  const urlencoded = new URLSearchParams()
  Object.entries(props)?.map(x => urlencoded.append(x[0], JSON.stringify(x[1])))

  const requestOptions = {
    method: props ? `POST` : `GET`,
    headers: myHeaders,
    body: urlencoded,
    redirect: `follow`,
  }
  return requestOptions
}
export const cacheControl = res => {
  res.setHeader(`Cache-Control`, `public, s-maxage=28.800, stale-while-revalidate=28.850`)
}
const checkErrorServer = async() => {
  let hostname = null
  try {
    hostname = await getInitServer()
  } catch (e) {
    process.env.BUILD === `false` && console.error(`Error getting server:`, e)

    // hostname = await checkErrorServer()
  }
  return hostname
}
export const getInitPage = cache(async(slug, sParam, seo = false) => {
  const hostname = await checkErrorServer()
  const pageInfo = await getPageData(
    hostname, slug, sParam, seo
  )
  return { hostname, pageInfo }
})
