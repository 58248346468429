// ? saber si es horario de verano
export const summerTime = () => {
  const today = new Date()
  const jan = new Date(today.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(today.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== today.getTimezoneOffset()
}

export const summerStore = () => {
  const today = new Date()
  const month = today.getMonth() + 1
  return [ 7, 8 ].includes(month)
}

export const tommddyyy = date => {
  const year = date.getFullYear()
  let month = (1 + date.getMonth()).toString()
  month = month?.length > 1 ? month : `0${month}`

  let day = date.getDate().toString()
  day = day?.length > 1 ? day : `0${day}`

  return `${month}/${day}/${year}`
}

export const getDate = () => {
  const date = new Date()
  return tommddyyy(date)
}
export const getTomorrow = () => {
  const date = new Date()
  date.setDate(date.getDate() + 1)
  return tommddyyy(date)
}
