import { getBrands, handleBrand } from "@/app/elastic/brands"
import { getCatalogue } from "@/app/elastic/catalogue"
import getProdList, { getCatalogueInfo, getInitialCatalogue } from "@/app/elastic/catalogueProducts"
import getSearchData from "@/app/elastic/catalogueSearch"
import { getBike } from "@/app/elastic/moto"
import getProduct from "@/app/elastic/product"
import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { languageIDHelper } from "@/utils/functions"
import { constructCatalogue, getMotoVOList, getPathFilter } from "@/utils/functions/catalogue"
import { constants } from "@/utils/global"
import { redirect, RedirectType } from 'next/navigation'

const getSizeGuide = async encrypt => {
  const sendData = {
    url: constants.URL.PRODUCT_GUIDES,
    encrypt,
    type: `POST`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message: sizeGuide } = await apiCall(encriptedData)
  return sizeGuide
}

export const getPageData = async(
  hostname, slug, params = {}, seo = false
) => {
  let pageType = config.CATALOGUE_PAGES.CATALOGUE
  const {
    redirection_301: redirection, staticMenu, langId: languageId, id: platformId, lang: codeLang, business, colors, login, webPages
  } = hostname

  let pageTexts = {}
  let uri = slug?.length > 2 ? `/${slug.slice(0, 2).join(`/`)}/` : `/${slug.join(`/`)}/`
  const redirectUrl = redirection.find(x => decodeURI(x.badUrl) === decodeURI(uri))
  let change = slug
  if (redirectUrl) {
    uri = decodeURI(redirectUrl.redirectionUrl)
    change = uri.split(`/`).filter(x => x !== ``)
  }

  const scriptFlag = change[0].includes(`-`)
  const tmpSlug = change[0].split(`-`)
  const itemProd = tmpSlug.at(-1)
  const tmpSlugVO = change[0].split(`-vo`)
  const itemProdVO = tmpSlugVO.at(-1)
  let body = {}
  let returnData = {}
  const isStatic = staticMenu?.filter(x => JSON.parse(x.value)[0]?.url.includes(change[0].toString()))
  switch (true) {
  case !isNaN(itemProd) && scriptFlag:
    pageType = config.CATALOGUE_PAGES.PRODUCT
    body = {
      platformId,
      languageId,
      abbrElastic: business?.abbrElastic,
      colors,
      prodId: itemProd,
      clientId: login?.user.clientId || hostname?.clientId,
      codeLang,
      userId: login?.user?.user_id || hostname?.user_id,
      seo
    }
    returnData = await getProduct(body, seo)
    if (returnData.id && decodeURIComponent(uri) !== returnData.path) {
      redirect(returnData.path, RedirectType.replace)
    }
    if (!seo) {
      const sizeGuideFetch = await getSizeGuide({ category: returnData.breadcrumbs[0].id, brand: returnData.brand.name })

      const [sizeGuide] = await Promise.all([sizeGuideFetch])
      pageTexts = {}
      returnData.prodRelations = [] // prodRelations  
      returnData.sizeGuide = sizeGuide ?? null
    }

    break
  case !isNaN(itemProdVO) && scriptFlag:
    pageType = config.CATALOGUE_PAGES.BIKE
    body = {
      platformId,
      languageId,
      abbrElastic: business?.abbrElastic,
    }
    const bikeInfoFetch = await getBike(body, itemProdVO)
    const [bikeInfo] = await Promise.all([bikeInfoFetch])
    returnData = await bikeInfo.message

    break
  case isStatic?.length > 0:
    pageType = config.CATALOGUE_PAGES.BRANDS
    const brandsFetch = await handleBrand(platformId, languageId, business?.abbrElastic)
    const [brands] = await Promise.all([brandsFetch])
    returnData = { banner: brands.result[0].banner, brandList: brands.result }
    break
  case change[0].toString() === `search`:
    pageType = config.CATALOGUE_PAGES.SEARCH
    if (params.s !== undefined) {
      const searchData = await getSearchData({ search: params.s, platformId, languageId, abbrElastic: business?.abbrElastic })
      if (searchData?.result !== null) {
        const catalogue = { ...searchData.result, path: `search` }
        const banner = types.banner.brand.xl
        const breadcrumbs = []
        const filters = getPathFilter(
          params, slug, catalogue.brands, hostname.lang.toLowerCase(), catalogue.filters, catalogue?.specialList
        )
        const { page, pathFilters } = filters
        const orderParams = {
          page,
          limit: constants.CATALOGO_LIMIT,
          user: login?.user?.user_id || hostname.user_id,
          clientId: login?.user.clientId || hostname.clientId,
          rate: hostname.rate,
          platform: hostname.id,
          language: languageIDHelper(hostname.lang.toLowerCase()),
          filters: pathFilters,
          categories: [],
          brand: [catalogue?.brand_id] || [],
        }

        body = await constructCatalogue(
          orderParams,
          catalogue?.chips,
          false,
          false,
          true,
          params.s,
          business?.abbrElastic,
        )

        const returnList = await getProdList(body)
        const prodList = getCatalogueInfo(
          returnList, false, orderParams.page, orderParams.limit
        )
        returnData = { breadcrumbs, banner, catalogue, prodList, category: false, search: true }
      }
    }
    break
  default:
    const category = await getCatalogue(change, hostname, login?.user.clientId || hostname?.clientId)
    if (category?.result === null) {
      pageType = config.CATALOGUE_PAGES.BRAND
      const restBrand = await getBrands(change, hostname, login?.user.clientId || hostname.clientId)
      if (!restBrand?.result) {
        redirect(`/`, RedirectType.push)
      }
      const { breadcrumbs, banner, ...catalogue } = restBrand.result
      const filters = getPathFilter(
        params, slug, catalogue.brands, hostname.lang.toLowerCase(), catalogue.filters, catalogue?.specialList
      )

      const { page, pathFilters } = filters
      const orderParams = {
        page,
        limit: constants.CATALOGO_LIMIT,
        user: login?.user?.user_id || hostname.user_id,
        clientId: login?.user.clientId || hostname.clientId,
        rate: hostname.rate,
        platform: hostname.id,
        language: languageIDHelper(hostname.lang.toLowerCase()),
        filters: pathFilters,
        categories: [],
        brand: [catalogue?.brand_id] || [],
      }

      body = await constructCatalogue(
        orderParams,
        catalogue?.chips,
        false,
        false,
        false,
        ``,
        business?.abbrElastic,
      )

      const returnList = await getProdList(body)
      const prodList = getCatalogueInfo(
        returnList, false, orderParams.page, orderParams.limit
      )
      returnData = { breadcrumbs, banner, catalogue, prodList, category: false, search: false }
    } else {
      const { path, level } = category.result || {}
      if (path !== `/${slug.slice(0, level?.level3 ? 2 : 1).join(`/`)}/`) {
        const newPath = `${path}${slug.slice(level?.level3 ? 2 : 1).join(`/`)}`
        const queryParams = new URLSearchParams(params).toString()
        redirect(queryParams ? `${newPath}?${queryParams}` : newPath, RedirectType.replace)
      }

      pageType = config.CATALOGUE_PAGES.CATALOGUE
      const { breadcrumbs, banner, ...catalogue } = category.result
      const filters = getPathFilter(
        params, slug, catalogue.brands, hostname.lang.toLowerCase(), catalogue.filters, catalogue?.specialList
      )
      const info = {
        chips: catalogue?.chips,
        meta: catalogue.info.id === 234,
        motoVo: catalogue.info.id === 227,
        search: false,
        searchText: ``,
        abbrElastic: business?.abbrElastic,
      }

      const { page, pathFilters } = filters
      const orderParams = {
        page,
        limit: constants.CATALOGO_LIMIT,
        user: login?.user?.user_id || hostname.user_id,
        clientId: login?.user.clientId || hostname.clientId,
        rate: hostname.rate,
        platform: hostname.id,
        language: languageIDHelper(hostname.lang.toLowerCase()),
        filters: pathFilters,
        categories: catalogue?.info?.search_category || (catalogue?.info?.id ? [catalogue?.info?.id] : []),
        brand: [],
      }

      body = await constructCatalogue(
        orderParams,
        catalogue?.chips,
        catalogue.info.id === 234,
        catalogue.info.id === 227,
        false,
        ``,
        business?.abbrElastic,
      )

      let returnList = []
      let prodList = {}

      // if (!seo) {
      if (body) {
        returnList = info.motoVo ?
          await getMotoVOList({
            ...orderParams,
            motoVO: true,
            filter: catalogue?.filter
          }, body) :
          await getProdList(body)
        prodList = getCatalogueInfo(
          returnList, info.motoVo, orderParams.page, orderParams.limit
        )
      } else {
        prodList = getInitialCatalogue(info.motoVo, orderParams.page, orderParams.limit)
      }

      // }
      returnData = { breadcrumbs, banner, catalogue, prodList, category: true, search: false }
    }
    break
  }
  return { type: pageType, data: returnData, pageTexts }
}

