'use client'
import { useEffect } from 'react'

export const scrollToTop = () => {
  const anchorElement = document.getElementById(process.env.NEXT_PUBLIC_ANCHOR)
  if (anchorElement) {
    anchorElement.scrollIntoView({ behavior: `smooth` })
  } else {
    globalThis.window.scrollTo(0, 0)
  }
}

const useScrollTop = props => {
  useEffect(() => {
    scrollToTop()
  }, [])
}

export default useScrollTop
