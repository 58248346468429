import { colors } from "@mui/material"
import { text } from "./subcomponents"

/* eslint-disable max-lines */
export const types = {
  web: `WEB`,
  searchCatalogue: {
    toggle: `toggle`,
    open: `open`,
    close: `close`,
  },
  tools: {
    linkLoading: `[linkLoading] Link Loading`,
    labels: `[labels] Labels product card`,
    colors: `[colors] Colors`,
    texts: `[text] Text`,
    currencies: `[currencies] Currencies`,
    shipping: `[shipping] Shipping`,
  },
  menuExpanded: {
    expanded: `expanded`,
    expandedCategory: `expandedCategory`,
    expandedSubCategory: `expandedSubCategory`,
    openMenu: `open`,
    openMenuAuto: `openAuto`,
    level1: `level1`,
    level2: `level2`,
    level3: `level3`,
  },
  mobileMenu: {
    openMenu: `open`,
    openMenuMobile: `open-menu-mobile`,
    index: `index`,
    category: `category`,
    openCategory: `openCategory`,
  },
  basket: {
    init: `[init] init to object`,
    showBasket: `[showBasket] Show basket`,
    showMenu: `[showMenu] Show Menu`,
  },
  catalogue: {
    init: `[init] Catalogue Init`,
    more: `[more] More info`,
    pathFilter: `[pathFilter] Attr path filter`,
    execute: `[execute] Execute call filters`,
    restart: `[Restart] Restart catalogue`,
    modalFilter: `[modalFilter] open filter modal`,
    loading: `[loading] display a loader when filter changes`,
  },
  products: {
    init: `[init] Products Init`,
    restart: `[restart] Restart productList`,
    list: `[list] List products`,
  },
  product: {
    init: `[init] Product Init`,
    restart: `[restart] Restart product`,
    more: `[more] Show more description`,
    list: `[list] List product`,
    img: `[img]`,
  },
  panelBuy: {
    restart: `[restart] Restart panel-buy`,
    setItem: `[setItem] Select item variant`,
    price: `[total] Change price`,
    total: `[total] Change total price`,
    unit: `[unit] Change unit`,
    received: `[received] Change received day`,
    ship: `[ship] Change ship cost`,
    init: `[init] Init object panel-buy`,
    comment: `[comment] Set new Comment`,
  },
  panelLayout: {
    restart: `[restart] Restart panel-buy`,
    setItem: `[setItem] Select item variant`,
    price: `[total] Change price`,
    total: `[total] Change total price`,
    unit: `[unit] Change unit`,
    received: `[received] Change received day`,
    ship: `[ship] Change ship cost`,
    init: `[init] Init object panel-buy`,
    comment: `[comment] Set new Comment`,
  },
  cartSteps: {
    restart: `[restart]`,
    init: `[init]init steps to first charge`,
    addrList: `[addrList] address list`,
    shipPickups: `[ShipPickups] ShipPickups list`,
    pdrsList: `[pdrsList] pdrsList list`,
    pdrsListIncludes: `[pdrsListIncludes] pdrsList list inlcudes`,
    pdrsListNotIncludes: `[pdrsListNotIncludes] pdrsList list not includes`,
    shipServs: `[shipServs]`,
    shipServsNull: `[shipServsNull]`,
    dbPdrId: `[dbPdrId]`,
    openInvAddr: `[openInvAddr]`,
    selPickup: `[selPickup]`,
    openBasket: `[openBasket]`,
    openShipAddr: `[openShipAddr]`,
    openShipServs: `[openShipServs]`,
    addr: `[addr]`,
    sedeMod: `[sedeMod]`,
    selShipServs: `[selShipServs]`,
    pickupSelected: `[pickupSelected]`,
    pickupModal: `[pickupModal]`,
    insurance: `[insurance]`,
    openPay: `[openPay]`,
    dpm: `[dpm]`,
    type: `[type]`,
  },
  path: {
    init: `[init] Pather list`,
  },
  cookies: {
    preference: { cookie: `[Cookies] View Cookies`, preferences: `preferences` },
    msg: { cookie: `[Cookies] Msg Cookies`, preferences: `message` },
  },
  preferences: {
    // [ LOGIN | LOGOUT ]
    login: `[Auth] Login`,
    logout: `[Auth] Logout`,
    register: `[Auth] register`,

    // AUTENTICATE
    auth: `[Auth] Autenticate`,
    builDate: `[Date] Build date compile`,
    currentVersion: `[Version] Current Version`,

    userLang: `[USER] Set User Language`,
    recoverPass: `[RECOVER PASS] Set Recover Password`,
    resetPass: `[RECOVER PASS] Set Reset Password`,
  },

  // ERROR's UI
  uiSetError: `[UI] Set Error`,
  uiRemoveError: `[UI] Remove Error`,
  uiStartLoading: `[UI] Start loading`,
  uiFinishLoading: `[UI] Finish loading`,

  // REDUCERS ACTIONS INIT
  initSearch: `[INIT] Init search`,
  updateSynonims: `[INIT] Update Synonims`,

  // parametros predefinidos para el init de la store
  stateInit: {
    categories: `categories`,
    brands: `brands`,
    products: `products`,
    list: `list`,
    countList: `countList`,
    filters: `filters`,
    synonims: `synonims`,
    related: `relatedSyn`,
  },
  synonymous: `synonymous`,

  // GESTION HOST
  init: `[INIT] Set Init`,
  initShip: `[INIT SHIP] Set Init Ship`,
  translate: `[INIT] Set Translate`,

  // GESTION MENU
  menu: `[MENU] Set Menu`,

  // GESTION MENU
  brand: `[BRAND] Set Brand`,

  // GESTION MENU
  phater: `[PATH] Set Path`,

  specialRoute: `[ROUTE] set special route`,

  // LANG's TRANSLATE
  lang: `[LANG] SetLang`,
  language: `[LANG] Set Language`,
  transDic: ` transDic`,

  // SEARCH RECIENTES
  search: `[SEARCH] Set Search`,
  toShow: `[SEARCH] Set Products to Show`,
  setStartEnd: `[SEARCH] Set Start & End`,
  setChips: `[SEARCH] Set suggestion chips`,

  // Construct object add synonims
  construct: {
    id: `id`,
    new: `new`,
    searchBar: `searchBar`,
    synonims: `synonims`,
    related_category: `related_category`,
    related_product: `related_product`,
  },

  // REGISTER
  register: `[REGISTER] Register`,
  removeRegister: `[REGISTER] Remove Register`,

  // REGISTER
  recover: `[RECOVER] Recover`,
  removeRecover: `[RECOVER] Remove Recover`,
  recoverPassword: `[RECOVER] Recover Password`,
  removeRecoverPassword: `[RECOVER] Remove Recover Password`,

  // REDIRECT
  redirectSet: `[REDIRECT] Set Redirect`,
  redirectRemove: `[REDIRECT] Remove Redirect`,

  // CARRITO
  initCart: `[CART] InitCart`,
  recoveryCart: `[CART] RecoveryCart`,
  cartSetVariant: `[CART] Set Variant Cart`,
  cartRemoveVariant: `[CART] Remove Variant Cart`,
  confirmCart: `[CART] Confirm Cart`,
  cartError: `[CART] Error Cart`,
  cartCheckout: `[CART] Checkout Cart`,
  cartErrorReturn: `return`,
  cartErrorClean: `delete`,

  // RELATED / CANONICAL PRODUCTS
  micro_canonicals: `[PRODUCTS] micro canonicals`,
  micro_related: `[PRODUCTS] micro related`,
  micro_breadcrumbs: `[PRODUCTS] micro breadcrumbs`,

  cart: {
    paypal: `[PayPal] calculate paypal`,
    addRemove: `[addRemove] add-remove item basket`,
    confirmed: `confirmed`,
    not_confirmed: `not confirmed`,
    ipinfo: `[ipinfo]`,
    insurance: `[insurance]`,
    cartCoupon: `[cartCoupon] List coupon`,
    currencies: `[Currency] List currencies`,
    currency: `[Currency] Currency`,
    restart: `[restart] restart cart`,
    extraComments: `[extraComments] Added Comments`,
    deliveryDate: `[deliveryDate] Added delivery Date`,
  },

  catalog: {
    brand: `brand`,
    variant: `variant`,
    category: `category`,
    product: `product`,
    price: `price`,
    priceRange: `priceRange`,
    color: `color`,
    word: `word`,
    sedes: `sedes`,
    page: `page`,
    get: `get`,
    meta: `meta`,
    order: {
      order: `order`,
      desc: `desc`,
      asc: `asc`,
      new: `new`,
    },
  },
  metaMoto: {
    type: `meta_moto`,
    brand: `brandMeta`,
    model: `model`,
  },
  motorbike: {
    type: `motorbike`,
    year: `year`,
  },
  banner: {
    brand: {
      xl: `/img/banner/eubk/brands/banner-xl.jpg`,
    },
    moto: {
      xl: `/img/banner/eubk/motos/banner-xl.jpg`,
    },
  },

  sedesId: {
    PRINCIPAL: 1,
    GRANADA: 2,
    MURCIA: 3,
    CENTRAL: 4,
  },
  store: { murcia: `murcia`, granada: `granada` },
  stores: [ `murcia`, `granada` ],
  sitemaps: [
    `sitemap_es.xml`, `sitemap_en.xml`, `sitemap_de.xml`, `sitemap_fr.xml`, `sitemap_it.xml`, `sitemap_pt.xml`
  ],

  offset: `offset`,
  variant: `variant`,
  limit: `limit`,

  order: {
    brand: `brand`,
    ref: `ref`,
    name: `name`,
    stock: `stock`,
    type: {
      desc: `DESC`,
      asc: `ASC`,
    },
  },

  // ORDERS SORTLABELS
  orders: {
    desc: `desc`,
    asc: `asc`,
  },
  sorter: {
    sequence: `sequence`,
  },

  // NAME INPUTS/SELECTS
  input: {
    send: `send`,
    client: `client`,
    user: `user`,
    userName: `username`,
    userProfile: `userProfile`,
    pass: `password`,
    recoverPass: `recoverPassword`,
    email: `email`,
    emailProfile: `emailProfile`,
    company: `company`,
    contact: `contact`,
    shipping: `shipping`,
    nif: `nif`,
    vatNumber: `vat_number`,
    attn: `attn`,
    name: `name`,
    lastName: `last_name`,
    phone: `phone`,
    phoneProfile: `phoneProfile`,
    web: `web`,
    adjunt: `adjunt`,
    prefix: `prefix`,
    optionDistrict: `optionDistrict`,
    optionState: `optionState`,
    optionCity: `optionCity`,
    optionTown: `optionTown`,
    order: `order`,
    message: `message`,
    subject: `subject`,
    rating: `rating`,
    title: `title`,
    comments: `comments`,
    pros: `pros`,
    cons: `cons`,
    options: {
      set: `set`,
      get: `get`,
    },
    dir: {
      name: `name`,
      email: `email`,
      phone: `phone`,
      vatNumber: `vat_number`,
      country: `country`,
      zip: `zip`,
      state: `state`,
      city: `city`,
      district: `district`,
      town: `town`,
      street: `street`,
      number: `number`,
      floor: `floor`,
      extra: `extra`,
      comment: `comment`,
      default_invoice_address: `default_invoice_address`,
      default_shipping_address: `default_shipping_address`,
    },
    buttons: {
      edit: `edit`,
      cancel: `cancel`,
      discard: `discard`,
      save: `save`,
      undo: `undo`,
    },
    visibility: `visibility`,
    visibilityOff: `visibilityOff`,
  },

  // COOKIES NAME
  cookie_name: {
    _cfduid: `_cfduid`,
    G_ENABLE_IDPS: `G_ENABLE_IDPS`,
    cookies_policy: `cookies_policy`,
    _EUROBIKES: `_EUROBIKES`,
    _ga: `_ga`,
    _gat_gtag: `_gat_gtag`,
    selected: `selected`,
  },

  preferenecs: {
    lang: `lang`,
  },

  // Array para las busquedas de variantes de la home
  listVariant: [
    `price`, `stock`, `new`, `to-pick`
  ],

  // Array para las busquedas de account
  account: [
    `profile`, `address`, `shop`, `ftp`
  ],

  // Tipos de profile
  profile: {
    info: `info`,
    pass: `password`,
  },

  // Sedes activas
  sedes: {
    central: `murcia`,
    tienda_murcia: `tienda_murcia`,
    tienda_granada: `granada`,
  },

  // Selector de direcciónes de envío
  select: {
    address: `address`,
    sedes: `sedes`,
  },

  // Columnas de pedidos a mostrar
  saleOrder: [
    `order_create_date`, `draft_reference`, `order_reference`, `reference`, `variant_name`, `state`, `amount`, `total`
  ],

  // Columnas de pedidos a mostrar MOBILE
  saleOrderMobile: [
    `order_create_date`, `draft_reference`, `order_reference`, `reference`, `state`, `variant_name`, `amount`, `total`
  ],

  // Estados de pedido
  saleOrderState: {
    draft: `draft`,
    waiting: `waiting`,
    reserved: `reserved`,
    transfered: `transfered`,
    invoiced: `invoiced`,
    canceled: `canceled`,
    "to-pick": `to-pick`,
    unpaid: `unpaid`,
  },

  // Tipos PDF
  pdf: {
    sale_order: `sale_order`,
    delivery_note: `delivery_note`,
    invoice: `invoice`,
  },

  // Tipos de pago con targeta
  cards: {
    new: `new-card`,
    other: `other-card`,
    old: `old-card`,
    list: `list-card`,
    remove: `remove-card`,
  },

  nav: {
    outlet: `outlet`,
    brand: `brand`,
    blackFriday: `black-friday`,
    b2b: `b2b`,
    dynamic: `dynamic`,
    static: `static`,
    blueDay: `blue-day`,
  },

  _menu: {
    menuOpen: `menu-open`,
    menuClose: `menu-closed`,
    open: `opened`,
    close: `closed`,
    login: `login`,
    logout: `logout`,
    cart: `cart`,
    search: `search`,
  },
  supportIcons: {
    buy: `/img/support-icons/web-buy.svg`,
    order: `/img/support-icons/web-order.svg`,
    received: `/img/support-icons/web-received.svg`,
  },
  defaultSupport: `make_order`,

  events: {
    blackFriday: `black-friday`,
    info: `info`,
    infoHome: `info-home`,
    promotion: `promotion`,
    blueDay: `blue-day`,
    special: `special-day`,
  },
  specialRoutes: {
    home: `home`,
    product: `product`,
    search: `search`,
    login: `login`,
    account: `account`,
    resetPassword: `reset-password`,
    cart: `cart`,
    store: `store`,
    shopMurcia: `shopMurcia`,
    shopGranada: `shopGranada`,
    cartConfirm: `cartConfirm`,
  },
  accountButtons: {
    info: `info`,
    orders: `orders`,
    wishlist: `wishlist`,
    addresses: `addresses`,
    payMethods: `payMethods`,
    coupons: `coupons`,
    ratings: `ratings`,
    bikes: `bikes`,
    newsletter: `newsletter`,
    tickets: `tickets`,
  },
  profileIcons: `/img/profile-icons/`,
  ticketIcons: {
    send: `/img/tools/send-chat.svg`
  },
  wishlistIcons: {
    complet: `icon-whistlist-complet.svg`,
    perfil: `icon-whistlist-perfil.svg`,
  },

  deleteProduct: {
    level1: `web`,
    level2: `cart`,
    level3: `shoppingBasket`,
    level4: `delete`,
  },

  selectAddress: [ 1, 2 ],
  tick: {
    on: `tick`,
    off: `notick`,
  },
  payment: {
    cash: `cash`,
    transferDoc: `transfer-doc`,
  },
  gtag: {
    eventType: {
      items: `view_item_list`,
      item: `view_item`,
      search: `view_search_results`,
      checkout: `begin_checkout`,
      viewCart: `view_cart`,
      purchase: `purchase`,
      purchaseTransfer: `purchase_transfer`,
      pageView: `page_view`,
      addToCart: `add_to_cart`,
      removeFromCart: `remove_from_cart`,
      addShippingInfo: `add_shipping_info`,
      addPaymentInfo: `add_payment_info`,
      generateLead: `generate_lead`
    },
    page: {
      search: `searchresults`,
      category: `category`,
      purchase: `purchase`,
    },
    criteo: {
      cart: {
        type: `checkout`,
        detail: `checkout`,
      },
      add: {
        type: `addToCart`,
        detail: `add`,
      },
    },
  },
  querys: {
    [`min-1380`]: `min-1380`,
  },
  imgType: [
    `png`, `jpg`, `jpeg`, `gif`
  ],
}

export default types
