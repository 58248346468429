/* eslint-disable max-lines */
import { types } from "@/types"

export const forms = {
  LOGIN: [
    {
      type: types.input.email,
      required: true,
      md: 12,
      inputType: types.input.email,
      name: `username`,
    },
    {
      type: types.input.pass,
      required: true,
      md: 12,
      inputType: ``,
      name: `current-password`,
    },
  ],
  REGISTER: [
    {
      type: types.input.name,
      required: true,
      md: 6,
      inputType: `text`,
      name: ``,
    },
    {
      type: types.input.lastName,
      required: false,
      md: 6,
      inputType: `text`,
      name: ``,
    },
    {
      type: types.input.email,
      required: true,
      md: 12,
      inputType: types.input.email,
      name: `email`,
    },
    {
      type: types.input.pass,
      required: true,
      md: 6,
      inputType: ``,
      name: `current-password`,
    },
    {
      type: types.input.recoverPass,
      required: true,
      md: 6,
      inputType: ``,
      name: `new-password`,
    },
  ],
  RESET_PASSWORD: [
    {
      type: types.input.pass,
      required: true,
      md: 12,
      inputType: ``,
      name: `current-password`,
    },
    {
      type: types.input.recoverPass,
      required: true,
      md: 12,
      inputType: ``,
      name: `new-password`,
    },
  ],
  RECOVERPASS: [
    {
      type: types.input.email,
      required: true,
      md: 12,
      inputType: types.input.email,
      name: `username`,
    },
  ],
  ACCOUNT: [
    {
      type: types.input.userName,
      required: true,
      md: 12,
      inputType: types.input.email,
      name: `username`,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.name,
      required: true,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: true,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.lastName,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: true,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.vatNumber,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: true,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.phone,
      required: false,
      md: 12,
      inputType: types.input.phone,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.email,
      required: false,
      md: 12,
      inputType: types.input.email,
      name: `email`,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.pass,
      required: true,
      md: 12,
      inputType: ``,
      name: `current-password`,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.recoverPass,
      required: true,
      md: 12,
      inputType: ``,
      name: `new-password`,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
  ],
  ACC_ADDR: [
    {
      type: types.input.name,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.attn,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.email,
      required: true,
      md: 12,
      inputType: types.input.email,
      name: `email`,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.phone,
      required: true,
      md: 12,
      inputType: types.input.phone,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.vatNumber,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.country,
      required: true,
      md: 12,
      inputType: `select`,
      name: `country`,
      disabled: true,
      textfield: false,
      autocomplete: true,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.zip,
      required: true,
      md: 12,
      inputType: `select`,
      name: `zip`,
      disabled: true,
      textfield: false,
      autocomplete: true,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.state,
      required: false,
      md: 12,
      inputType: `select`,
      name: `state`,
      disabled: true,
      textfield: false,
      autocomplete: true,
      check: false,
      show: false,
      company: `bym`,
    },
    {
      type: types.input.dir.district,
      required: false,
      md: 12,
      inputType: `select`,
      name: `district`,
      disabled: true,
      textfield: false,
      autocomplete: true,
      check: false,
      show: false,
      company: `bym`,
    },
    {
      type: types.input.dir.city,
      required: false,
      md: 12,
      inputType: `select`,
      name: `city`,
      disabled: true,
      textfield: false,
      autocomplete: true,
      check: false,
      show: false,
      company: `bym`,
    },
    {
      type: types.input.dir.town,
      required: true,
      md: 12,
      inputType: `select`,
      name: `town`,
      disabled: true,
      textfield: false,
      autocomplete: true,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.street,
      required: true,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.number,
      required: true,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.floor,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.extra,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.comment,
      required: false,
      md: 12,
      inputType: `text`,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.default_shipping_address,
      required: false,
      md: 6,
      inputType: `check`,
      name: ``,
      disabled: false,
      textfield: false,
      autocomplete: false,
      check: true,
      show: true,
      company: `all`,
    },
    {
      type: types.input.dir.default_invoice_address,
      required: false,
      md: 6,
      inputType: `check`,
      name: ``,
      disabled: false,
      textfield: false,
      autocomplete: false,
      check: true,
      show: true,
      company: `all`,
    },
  ],
  ACC_PHONE: [
    {
      type: types.input.phone,
      required: true,
      md: 12,
      inputType: types.input.phone,
      name: ``,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      show: true,
      company: `all`,
    }
  ],

  CONTACT: [
    {
      type: types.input.name,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.name,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.email,
      required: true,
      md: 6,
      inputType: types.input.email,
      name: types.input.email,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.phone,
      required: true,
      md: 6,
      inputType: types.input.phone,
      name: types.input.phone,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.order,
      required: false,
      md: 12,
      inputType: `select`,
      name: types.input.order,
      disabled: false,
      textfield: false,
      autocomplete: true,
      check: false,
    },
    {
      type: types.input.message,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.message,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
  ],
  NOTIFY_DIS: [
    {
      type: types.input.email,
      required: true,
      md: 12,
      inputType: types.input.email,
      name: types.input.email,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
  ],
  RATING_FORM: [
    {
      type: types.input.rating,
      required: true,
      md: 6,
      inputType: `text`,
      name: types.input.rating,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.title,
      required: true,
      md: 6,
      inputType: `text`,
      name: types.input.title,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.comments,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.comments,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.pros,
      required: false,
      md: 12,
      inputType: `text`,
      name: types.input.pros,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
    {
      type: types.input.cons,
      required: false,
      md: 12,
      inputType: `text`,
      name: types.input.cons,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
    },
  ],

  BIKE_CONTACT: [
    {
      type: types.input.name,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.name,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 2
    },
    {
      type: types.input.email,
      required: true,
      md: 6,
      inputType: types.input.email,
      name: types.input.email,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 1
    },
    {
      type: types.input.phone,
      required: false,
      md: 6,
      inputType: types.input.phone,
      name: types.input.phone,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 1
    },
    {
      type: types.input.message,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.message,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 2
    },
  ],

  PRODUCT_FORM: [
    {
      type: types.input.name,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.name,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 2
    },
    {
      type: types.input.email,
      required: true,
      md: 6,
      inputType: types.input.email,
      name: types.input.email,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 1
    },
    {
      type: types.input.phone,
      required: true,
      md: 6,
      inputType: types.input.phone,
      name: types.input.phone,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 1
    },
    {
      type: types.input.message,
      required: true,
      md: 12,
      inputType: `text`,
      name: types.input.message,
      disabled: false,
      textfield: true,
      autocomplete: false,
      check: false,
      colspan: 2
    },
  ],
}
