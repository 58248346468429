/* eslint-disable default-param-last */
import { types } from "@/types"
import { initObj } from "./Obj"
export const toolsReducer = (state = initObj, action) => {
  switch (action.type) {
  case types.tools.colors:
    return {
      ...state,
      colors: action.payload
    }
  case types.tools.texts:
    return {
      ...state,
      texts: action.payload
    }
  case types.tools.currencies:
    return {
      ...state,
      currencies: action.payload
    }
  case types.tools.shipping:
    return {
      ...state,
      shipping: action.payload
    }
  default:
    return state
  }
}
