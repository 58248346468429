import { Base64 } from "js-base64"

const crypto = require(`crypto`)

// =================================================================================================================
//                  MÉTODOS GLOBALES PARA ENCRIPTAR/DESENCRIPTAR DATOS. ACCESIBLE A TRAVÉS DE secure
// =================================================================================================================
export const secure = {
  // START - CONFIGURACIÓN PARA ENCRIPTAR/DESENCRIPTAR DATOS
  _ENCRYPTION: process.env.NEXT_PUBLIC_ENCRYPTION,
  _ENCRYPTION_KEY: process.env.NEXT_PUBLIC_ENCRYPTION_KEY,
  _ENCRYPTION_IV: process.env.NEXT_PUBLIC_ENCRYPTION_IV,
  _ENCRYPTION_ENCODE: process.env.NEXT_PUBLIC_ENCRYPTION_ENCODE,

  // END - CONFIGURACIÓN PARA ENCRIPTAR/DESENCRIPTAR DATOS

  /**
   * Encripta información para el envío por apiCall
   * @param object|string   data   Datos a encriptar, en función del parámetro "type" será un objeto json o un string
   * @param bool    isJson Por defecto "true". Indica si los datos que se tienen que devolver se hará en formato JSON.
   */
  encrypt(data, btoaFlag, isJson = true) {
    let newData = data
    if (isJson) {
      const currentTimestamp = new Date().getTime()
      const phpCurrentTimestamp = Math.trunc(currentTimestamp / 1000)

      newData.api_time = phpCurrentTimestamp
      newData = JSON.stringify(newData)
    }

    const base64data = btoaFlag ? Base64.btoa(newData) : Base64.encode(newData)

    const encryptor = crypto.createCipheriv(this._ENCRYPTION, this._ENCRYPTION_KEY, this._ENCRYPTION_IV)

    encryptor.setEncoding(this._ENCRYPTION_ENCODE)
    encryptor.write(base64data)
    encryptor.end()

    const hashEncodedUri = encodeURI(encryptor.read())

    return hashEncodedUri
  },
  token(data) {
    const currentTimestamp = new Date().getTime()
    const phpCurrentTimestamp = Math.trunc(currentTimestamp / 1000)
    const base64data = btoa(`${data}-${phpCurrentTimestamp}`)

    const encryptor = crypto.createCipheriv(this._ENCRYPTION, this._ENCRYPTION_KEY, this._ENCRYPTION_IV)

    encryptor.setEncoding(this._ENCRYPTION_ENCODE)
    encryptor.write(base64data)
    encryptor.end()

    const hashEncodedUri = encodeURI(encryptor.read())

    return hashEncodedUri
  },

  /**
   * Desencripta información obtenida por apiCall.
   * @param object|string   data            Datos a encriptar, en función del parámetro "type" será un objeto json o un string
   * @param bool            clientSide      Por defecto: true. Indica si estamos en el lado cliente o servidor.
   * @param bool            isJson Por defecto "true". Indica si los datos que se tienen que devolver se hará en formato JSON.
   */
  decrypt(data, clientSide = true, isJson = true) {
    try {
      const decryptor = crypto.createDecipheriv(this._ENCRYPTION, this._ENCRYPTION_KEY, this._ENCRYPTION_IV)
      const dataUriDecoded = decodeURI(data)
      const base64data = decryptor.update(dataUriDecoded, this._ENCRYPTION_ENCODE, `utf8`) + decryptor.final(`utf8`)

      let decoded = ``

      if (clientSide) {
        decoded = atob(base64data)
      } else {
        decoded = Base64.decode(base64data)
      }

      const reply = isJson ? JSON.parse(decoded) : decoded

      return reply
    } catch (Error) {
      return false
    }
  },
}

export const cookie = {
  getCookie(cname) {
    const name = `${cname}=`
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie?.split(`;`)

    for (let i = 0; i < ca?.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ` `) {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name?.length, c?.length)
      }
    }
    return ``
  },
  setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    const expires = `expires=${d.toUTCString()}`
    document.cookie = `${cname}=${cvalue};${expires};path=/`
  },
}

export const encodeHashJS = (plainWord = process.env.NEXT_PUBLIC_SECURE_HASH, withDate = true) => {
  const num1 = Math.floor(Math.random() * 900) + 100
  const num2 = Math.floor(Math.random() * 900) + 100
  const firstDigit = num2.toString().charAt(0)
  const sum = parseInt(firstDigit, 10) + 2
  let toEncode = plainWord
  if (withDate) {
    toEncode = `${plainWord}-${Date.now() / 1000}`
  }
  let encodedWord = toEncode
  for (let i = 0; i < sum; i++) {
    encodedWord = Base64.btoa(unescape(encodeURIComponent(encodedWord)))
  }
  encodedWord = `$${num1}${encodedWord}`
  encodedWord += num2

  return encodedWord
}

export const decodeHashJS = (encodedWord, returnJson = true) => {
  if (!encodedWord) {
    return false
  }
  let word = encodedWord.substring(4)
  word = word.substring(0, word.length - 3)
  const firstDigit = parseInt(encodedWord.charAt(encodedWord.length - 3), 10)
  const sum = firstDigit + 2
  for (let i = 0; i < sum; i++) {
    word = Base64.atob(word)
    word = decodeURIComponent(escape(word))
  }
  return returnJson ? JSON.parse(word) : word
}
