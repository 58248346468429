export const text = {
  hometext: `hometext`,
  addrBtnText: `addrBtnText`,
  legalText: `legalText`,
  textQuestion: `textQuestion`,
  textHistory: `textHistory`,
  labelsTexts: `labelsTexts`,
  texts: `texts`,
  errorPay: `errorPay`,
  formTexts: `formTexts`,
  orderTexts: `orderTexts`,
  textStore: `textStore`,
  textTitleH: `textTitleH`,
  textDescription: `textDescription`,
  textMore: `textMore`,
  textSelect: `textSelect`,
  emptyText: `emptyText`,
  lineText: `lineText`,
  stepCartTitle: `stepCartTitle`,
  accForm: `accForm`,
  errorMsg: `errorMsg`,
}
