import { source } from "@/types"

export const getChipCategories = async(
  body, elk, brandId, categories
) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [
    { "level2.keyword": `desc` },
    { "info.sequence": `desc` },
    { "info.h1.keyword": `asc` }
  ]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { category_id: x }}))
  brandId !== null && should.push({ match: { [`info.brands`]: brandId }})
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const obj = {
    index: `${abbrElastic}_web_menu_elk`,
    size: 1000,
    sort,
    _source: source.chipCategories,
    query: {
      bool: {
        minimum_should_match,
        should,
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}
export const getChipBrands = async(body, elk, brands) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [
    { "level2.keyword": `desc` },
    { "info.sequence": `desc` },
    { "info.h1.keyword": `asc` }
  ]
  const minimum_should_match = 1
  const should = brands.map(x => ({ match: { brand_id: x }}))
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const obj = {
    index: `${abbrElastic}_web_brands_elk`,
    size: 1000,

    // sort: sort ,
    _source: source.chipBrands,
    query: {
      bool: {
        minimum_should_match,
        should,
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

export const getFilters = async(
  body, elk, categories, metaMoto, searchMoto = false
) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [{ sequence: { order: `desc` }}, { attribute_id: { order: `asc` }}]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { categories: x }}))
  if (metaMoto) {
    sort.unshift({ meta_moto: { order: `desc` }})
    should.unshift({ match: { meta_moto: 1 }})
  }
  if (searchMoto) {
    should.unshift({ match: { motorbike: 1 }})
  }
  const obj = {
    index: `${abbrElastic}_web_filters_elk`,
    sort,
    from: 0,
    size: 100,
    _source: [
      `name`, `translations`, `attribute_id`, `attributes`, `sequence`, `meta_moto`, `motorbike`, `type`
    ],
    query: {
      bool: {
        minimum_should_match,
        should,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}
export const getFilterCategory = async(body, elk, categoryId) => {
  const { platformId, languageId, abbrElastic } = body
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const result = await elk.search({
    index: `${abbrElastic}_web_menu_elk`,
    size: 500,
    sort: [
      {
        "parent.sequence": {
          order: `desc`
        }
      },
      {
        "info.sequence": {
          order: `desc`
        }
      },
      {
        "info.h1.keyword": {
          order: `asc`
        }
      }
    ],
    _source: source.staticCat,
    query: {
      bool: {
        must_not: [
          {
            term: {
              category_id: categoryId,
            },
          },
          {
            match: {
              "info.children": 1
            }
          }
        ],
        minimum_should_match: 1,
        should: [
          {
            match: {
              level2: 1
            }
          },
          {
            match: {
              level3: 1
            }
          },

          // {
          //   match: {
          //     special: 1
          //   }
          // },
          // {
          //   match: {
          //     blackFriday: 1
          //   }
          // },
          // {
          //   match: {
          //     news: 1
          //   }
          // },
          // {
          //   match: {
          //     outlet: 1
          //   }
          // },
        ],
        filter,
      },
    },
  },
  { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source) || [],
  }
}
