import {
  acc,
  accordion,
  button,
  chat,
  chip,
  cookies,
  dialog,
  divider,
  filter,
  footer,
  grid,
  home,
  info,
  legal,
  link,
  list,
  menu,
  paper,
  search,
  select,
  support,
  table,
  text,
  utils
} from "./subcomponents"

export const cmp = {
  ...dialog,
  ...paper,
  ...select,
  ...chip,
  ...info,
  ...search,
  ...menu,
  ...list,
  ...acc,
  ...accordion,
  ...footer,
  ...grid,
  ...text,
  ...legal,
  ...link,
  ...table,
  ...button,
  ...filter,
  ...divider,
  ...home,
  ...support,
  ...cookies,
  ...utils,
  ...chat,
}
export default cmp
