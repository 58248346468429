export const dialog = {
  dialogTitleCookie: `dialogTitleCookie`,
  dialogContentCookie: `dialogContentCookie`,
  dialogSubContentCookie: `dialogSubContentCookie`,
  dialogButtonCookie: `dialogButtonCookie`,
  pickupModal: `pickupModal`,
  pickupTitleModal: `pickupTitleModal`,
  pickupContentModal: `pickupContentModal`,
  payModal: `payModal`,
  payContentModal: `payContentModal`,
}
