import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { constants } from "@/utils/global"
import moment from "moment"
import { fixTwoDecimal, languageIDcomplet, sorter } from ".."

const {
  MOMENT_FORMAT: { days, month, annio },
} = constants
export const constructProduct = (
  props, motovo = false, wbd = false, elk = false
) => {
  const temp = [
    ...new Set(props?.map(x => {
      return {
        cost: fixTwoDecimal(motovo ? x?.price : x.price?.cost || x?.pack?.cost),
        pvp: fixTwoDecimal(x.price?.pvp || x?.pack?.pvp),
        discount: x.price?.discount || x?.pack?.discount,
        image: x.images ? x.images[0].image : ``,
        imgalt: x.images ? x.images[0].alt : ``,
        prod_id: elk ? x.product_id : x.id,
        // eslint-disable-next-line prefer-template
        url: elk || wbd ? x.webdata?.url : x?.webdata?.url + `-${motovo ? `vo` : ``}` + x.id,
        title: elk ? `${x.webdata?.prefix ?? ``} ${x.webdata?.h1}` : wbd ? x.webdata?.h1 : x?.webdata?.h1,
        variants: x.variant,
        categories: elk ? x.categories : x.product_categories,
        product_categories_name: x?.product_categories_name || null,
        brand: elk || wbd ? x.variant[0].brand_name : x.brand,
        score: elk ? {
          score: x.reviews.score,
          total: x.reviews.count
        } : x.score,
        wishlist: x.wishlist || null,
        kms: x?.kms || null,
        year: x?.year || null,
        createDate: x?.create_date || null,
        releaseDate: x?.release_date || null,
        isProf: x?.professional || null,
        reserved: x?.sold || null,
        coupons: x?.coupons || false,
        condition: x?.price?.variant ?? null
      }
    })),
  ]
  return { items: temp }
}

export const apiCallWishlist = async(prop, wish) => {
  const { login, item } = prop
  const encrypt = {
    uid: login?.user?.user_id || null,
    productId: item,
  }
  const sendData = {
    url: constants.URL.PRODUCT_WISH,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  await wish(message.wish)
}

export const sortCategory = async(product, paths, bike) => {
  const arr = await paths.filter(x => !bike ? product.webdata?.product_categories.includes(x.id.toString()) : product?.categories.includes(x.id))
  const tmpArr = arr?.length > 1 ? await sorter(arr, `fatherSequence`)[0] : arr[0]
  let endArr = []
  const defObj = {
    id: tmpArr.id,
    info: tmpArr,
    h1: tmpArr.info.h1,
    path: `${tmpArr.path}/`,
  }
  switch (tmpArr.breadCrumbs?.length) {
  case 1:
    endArr = [defObj]
    break

  default:
    endArr = [
      {
        id: tmpArr.parent.id,
        h1: tmpArr.parent.h1,
        path: `/${tmpArr.parent.url}/`,
      },
      defObj,
    ]
    break
  }
  endArr.push({ h1: product.webdata.h1 })
  return [endArr]
}

const constructOptionCanonical = props => {
  const data = {
    product: props.product,
    platform: props.platform,
    uid: props.uid,
  }
  if (props.languageId) {
    data.language = props.languageId
  } else {
    const { id } = languageIDcomplet()
    data.language = id
  }
  return data
}
export const apiCallCanonical = async({ ...props }, set) => {
  const encrypt = await constructOptionCanonical(props)
  const sendData = {
    url: constants.URL.PRODUCT_CANONICAL,
    encrypt,
    type: `POST`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  !error && await set(message)
  return !error && message
}

export const apiCallSizeGuides = async({ ...props }, set) => {
  const encrypt = {
    category: props.category,
    brand: props.brand
  }
  const sendData = {
    url: constants.URL.PRODUCT_GUIDES,
    encrypt,
    type: `POST`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  !error && await set(x => message.link)
  return !error && message.link
}

export const dateToReceive = async({
  variantId, amount, months, userId, ...props
}) => {
  const encrypt = {
    type: `web`,
    variant_id: variantId,
    amount,
    user_id: userId || null,
    platform: props.platform,
    address: props.address,
    country: props.country,
  }

  // Realizamos la llamada apiCall
  const sendData = {
    url: constants.URL.TO_RECEIVE,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)

  // const controlDate = new Date().getTime()
  const date = moment().add(message.delay_days, `days`)

  // .format(`${days}-${month}-${annio}`)

  return {
    day: date.format(days),
    month: months[date.format(month)],
    annio: date.format(annio),
  }
}

export const stockShops = async({ variantId, amount }) => {
  const encrypt = {
    variant_id: variantId,
    amount
  }

  const sendData = {
    url: constants.URL.STOCK_SHOPS,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return message.stock
}

export const constructNotify = props => {
  const data = {}
  if (props.pack) {
    props.product.variant.forEach(x => {
      data.pack = `${x.variant_id},${data.pack || ``}`
    })
    data.variant = null
  } else {
    data.pack = null
    data.variant = props.variant || props.item.variant_id
  }
  data.language = props.lang
  data.email = props.email
  data.uid = props.uid
  data.brand = props.product.brand_id
  data.product = props.product.id
  data.checked = props.checked
  return data
}
export const apiCallNotify = async props => {
  const encrypt = await constructNotify(props)
  const sendData = {
    url: constants.URL.PRODUCT_NOTIFY,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return {
    error,
    message
  }
}

export const conditionProduct = (product, flag = false, condition = false) => {
  const obj = {
    0: `http://schema.org/RefurbishedCondition`,
    1: `http://schema.org/DamagedCondition`,
    default: `http://schema.org/NewCondition`,
  }
  const objFc = {
    0: `refurbished`,
    1: `used`,
    default: `new`,
  }
  const o = flag ? objFc : obj
  const v = condition ? product?.condition : product?.variants?.[0] || {}
  return o[v?.reconditioned === 1 && 0] || o[v?.bargain === 1 && 1] || o.default
}

export const getProductPrice = product => {
  return product.price?.cost || product?.pack?.cost
}
