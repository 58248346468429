import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import connectELK from "@/utils/elk/connect"
import { constants } from "@/utils/global"

const getBreadCrumbObj = async body => {
  const elk = await connectELK()
  const { platformId, languageId, categoryId, abbrElastic } = body
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const result = await elk.search({
    index: `${abbrElastic}_web_menu_elk`,
    _source: [`breadcrumbs`],
    query: {
      bool: {
        must: [
          {
            term: {
              category_id: categoryId,
            },
          }
        ],
        filter,
      },
    },
  },
  { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source).at(0),
  }
}

export const getBike = async(prop, itemVO) => {
  const { platformId, languageId, abbrElastic } = prop
  const encrypt = { bikeId: itemVO, langId: languageId }
  const sendData = { url: `${constants.URL.BIKE_INFO}`, encrypt, type: `POST` }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

  const { error, message } = await apiCall(encriptedData)
  const { result } = await getBreadCrumbObj({ platformId, languageId, categoryId: message.categories.at(0), abbrElastic })
  const { breadcrumbs } = await result
  return { error, message: { ...message, breadcrumbs: [ ...breadcrumbs, { id: null, h1: message.webdata.h1 }].flat() }}
}
