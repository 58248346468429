export const apiCallElk = async({ url, encrypt, type }, local = false) => {
  // Preparamos el header de la llamada
  const myHeaders = new Headers()
  myHeaders.append(`Content-Type`, `application/json`)

  // myHeaders.append("username", "eurobikes")
  // myHeaders.append("password", "*g077aC@tHEm@LL..*")

  const raw = JSON.stringify(encrypt)
  let controller = null
  controller = new AbortController()

  // Preparamos la cabecera de opciones para la llamada
  const requestOptions = {
    method: type,
    headers: myHeaders,
    signal: controller.signal,
    cache: `no-store`,

    // next: { revalidate: 3600 },
  }

  if (type === `POST`) {
    requestOptions.body = raw
    requestOptions.cors = `no-cors`
  }

  // Montamos la llamada fetch async/await con el control de try - catch para controlar el error
  try {
    const response = await fetch(!local ? `/api${url}` : url, requestOptions)
    const result = await response.json()
    result.controller = controller
    return result
  } catch (err) {
    // Pintamos por consola el mensage de error
    console.error(err)
  }
}
