export const source = {
  productCard: [
    `webdata.h1`,
    `webdata.url`,
    `webdata.title`,
    `webdata.prefix`,
    `variant.brand_name`,
    `reviews`,
    `path`,
    `images`,
    `pack`,
    `price`,
    `sort`,
    `product_id`,
    `sequence`,
    `categories`
  ],
  variantCard: [
    `webdata.h1`,
    `webdata.url`,
    `webdata.title`,
    `webdata.prefix`,
    `variant.marca`,
    `variant.brand_id`,
    `variant.id`,
    `path`,
    `images`,
    `price`,
    `sort`,
    `product_id`,
    `variant_id`,
    `sequence`,
    `categories`
  ],
  staticCat: [
    `info.h1`,
    `info.prefix`,
    `info.id`,
    `info.url`
  ],
  chipCategories: [
    `info.h1`,
    `info.id`,
    `info.url`,
    `path`
  ],
  chipBrands: [
    `webdata.h1`,
    `brand_id`,
    `path`
  ],
  labels: [
    `category_id`, `special`, `blackfriday`, `outlet`, `news`, `color`, `info.h1`
  ],
  currencies: [
    `id`,
    `code`,
    `symbol`,
    `exchange`,
    `translate`,
    `default`
  ],
  bikeFilters: [
    `attribute_id`,
    `attributes.id`,
    `attributes.sequence`,
    `attributes.translations`,
    `translations`,
    `sequence`,
    `value`,
  ],
  synonyms: [
    `id`,
    `name`,
    `synonyms`,
  ]
}
export default source
