/* eslint-disable no-param-reassign */
/* eslint-disable max-lines */
import { apiCall } from "@/middleware/api"
import { config } from "@/middleware/config/config"
import { types } from "@/types"
import { btoa } from "js-base64"
import moment from "moment"
import { constants } from "../global"
import { encodeHashJS } from "@/middleware/api/secure"

const {
  URL: {
    DESTINATIONS, PROFILE_PDF, TRACKING, TO_RECEIVE
  },
  MOMENT_FORMAT: { days, month, annio },
} = constants

export const cleanPath = path => path?.endsWith(`/`) ? path.slice(0, -1) : path
export const cleanStartString = (param, search) => param?.startsWith(search) ? param.slice(1) : param
export const cleanEndString = (param, search) => param?.endsWith(search) ? param.slice(0, -1) : param
export const removePronouns = (search, stopWords) => {
  return search?.split(`+`).filter(x => !stopWords.includes(x))
}

/**
 * Se encarga de recuperar el id de Idioma
 *
 * @returns
 */
export const languageDomainHelper = (domain = `com`) => {
  let id = `EN`
  switch (domain) {
  case `io`:
  case `com`:
    id = `ES`
    break
  case `net`:
    id = `DE`
    break
  case `uk`:
    id = `EN`
    break
  case `fr`:
    id = `FR`
    break
  case `it`:
    id = `IT`
    break
  case `pt`:
    id = `PT`
    break
  default:
    id = `EN`
  }

  // Retornamos el id correspondiente
  return id
}

/**
 * Se encarga de recuperar el dominio y el idioma apra renderizar el idioma correspondiente
 *
 * @returns
 */
export const languageHelper = () => {
  // Desestructuramos las constantes que vamos a usar
  const HOSTNAME = process.env.NODE_ENV !== `development` && globalThis?.window?.location?.hostname !== `localhost` ? globalThis?.window?.location?.hostname : config.HOSTNAME

  // Recogemos del servidor el idioma
  let idioma = process.env.NEXT_PUBLIC_LANG || `ES`

  // Parseamos el hostname para obtener el host a usar
  const hostname = HOSTNAME?.split(`.`)
  const domain = hostname[hostname?.length - 1]

  idioma = [ `com`, `io` ].includes(domain) && idioma === `ES` ? idioma : [ `com`, `io` ].includes(domain) && ![
    `ES`, `IT`, `EN`, `DE`, `PT`, `FR`
  ].includes(idioma) ? `EN` : languageDomainHelper(domain)

  // Retornamos un objeto formado por las variables domain y idioma
  return {
    domain,
    idioma
  }
}

/**
 * Se encarga de recuperar el id de Idioma
 *
 * @returns
 */
export const languageIDHelper = (idioma = `ES`) => {
  let id = 1
  switch (idioma.toLowerCase()) {
  case `es`:
    id = 1
    break
  case `de`:
    id = 2
    break
  case `en`:
    id = 3
    break
  case `fr`:
    id = 4
    break
  case `it`:
    id = 5
    break
  case `pt`:
    id = 6
    break
  default:
    id = 1
  }

  // Retornamos el id correspondiente
  return id
}

export const languageCodeHelper = (lang = 1) => {
  let id = `EN`
  switch (lang) {
  case 1:
    id = `ES`
    break
  case 2:
    id = `DE`
    break
  case 3:
    id = `EN`
    break
  case 4:
    id = `FR`
    break
  case 5:
    id = `IT`
    break
  case 6:
    id = `PT`
    break
  default:
    id = `EN`
  }
  return id
}

export const platformDomainHelper = (domain = `com`) => {
  let id = 1
  switch (domain) {
  case `io`:
  case `com`:
    id = 1
    break
  case `net`:
    id = 17
    break
  case `uk`:
    id = 16
    break
  case `fr`:
    id = 18
    break
  case `it`:
    id = 19
    break
  case `pt`:
    id = 20
    break
  default:
    id = 1
  }

  // Retornamos el id correspondiente
  return id
}

/**
 * Función completa para obtener el ID de idioma
 * @returns
 */
export const domainComplet = () => {
  // Ejecutamos la función importada del helper desestruturando la respuesta
  const { domain, idioma } = languageHelper()

  const languageId = languageIDHelper(idioma)
  const platformId = platformDomainHelper(domain)

  return {
    domain,
    language: idioma,
    id: languageId,
    platformId
  }
}
export const languageIDcomplet = () => {
  return domainComplet()
}

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format(`DD-MM-YYY HH:MM`)
  return buildDate
}

/**
 * Función encargada de retornar los array de relaciones con un codigo postal
 */
export const searchLocations = async({ ...props }) => {
  // Desestructuramos las props
  const { country, zip } = props

  // Realizamos la llamada apiCall
  const sendData = {
    url: `${DESTINATIONS}${country}/${encodeURI(zip?.label)}`,
    encrypt: ``,
    type: `GET`,
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const result = await apiCall(encriptedData)

  // const res = await result?.message

  return result?.message
}

/**
 * Se encarga de ejecutar la ancla de la pagina
 */
export const handleAnchor = (anchorage = null) => {
  anchorage === null && globalThis.window?.scrollTo({
    top: 0,
    behavior: `smooth`
  })
  if (anchorage !== null) {
    const anchor = document?.querySelector(anchorage || `#${process.env.NEXT_PUBLIC_ANCHOR}`)

    if (anchor) {
      anchor.scrollIntoView({
        behavior: `smooth`,
        block: `center`
      })
    }
  }
}

export const handleScrollTo = (name = ``) => {
  const targetElement = document.getElementById(name)
  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: `smooth`,
      block: `start`,
    })
  }
}

// Función encargada de descargar los pdf
const showFile = (blob, file) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: `application/pdf` })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement(`a`)
  link.href = data
  link.download = file
  link.click()
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}

/**
 * Función encargada de retornar el enlace del pdf solicitado
 */
export const downloadPDF = async({ ...props }) => {
  // Desestructuramos las props
  const { type, id } = props

  const encrypt = {
    data: {
      model: type,
      order_id: id,
    },
  }

  // Realizamos la llamada apiCall
  const sendData = {
    url: `${PROFILE_PDF}`,
    encrypt,
    type: `POST`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)

  fetch(message.pdf_link).
    then(r => r.blob()).
    then(r => {
      showFile(r, message.pdf_link.substr(message.pdf_link.lastIndexOf(`/`) + 1))
    })
}

/**
 * Función encargada de retornar el enlace del pdf solicitado
 */
export const showTracking = async props => {
  // Realizamos la llamada apiCall
  const url = await fetch(`${TRACKING}${props}`).then(res => res.json())

  url.forEach(x => {
    const link = document.createElement(`a`)
    link.href = x
    link.target = `_blank`
    link.click()
  })
}

/**
 *
 * @param {*} a parametro de entrada con useId
 * @param {*} b parametro de entrada con el indice aplicado
 * @returns retorna un string con la concatenación de ambos parametros
 */
export const handleKeyRandom = (a, b) => {
  const num = Math.floor(Math.random() * 1000)
  return `${a}_${b}_${num}`
}
export const handleKey = (a, b) => {
  return `${a}_${b}`
}

export const handleGoTo = (a, b) => {
  const link = document.createElement(`a`)
  link.href = a
  link.target = b
  link.click()
}

export const infoLog = () => {
  const svg = `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 226 50.7" style="enable-background:new 0 0 226 50.7;" xml:space="preserve">
		<style type="text/css">
		.st0{fill:#003366;}
		.st1{display:none;fill:#003366;}
		</style>
		<g id="c">
		<g>
		<path class="st0" d="M17.8,10.6H18c0.6,0.1,0.8,0.4,0.8,0.9v4.2c0,0.4-0.4,0.8-0.8,0.8H8.3c-1.1,0-1.1,0-1.1,1.1v4.9
		c0,0.2,0.2,0.3,0.3,0.3h10.1c0.4,0,0.8,0.4,0.8,0.8v3.8c0,0.4-0.4,0.8-0.8,0.8H7.5c-0.2,0-0.3,0.2-0.3,0.3V32
		c0,2.1,0.6,2.8,2.6,2.8H18c0.4,0,0.8,0.4,0.8,0.8v3.7c0,0.5-0.2,0.7-0.8,0.8h-0.2c-1.3,0.2-3.4,0.5-7.5,0.5c-4.8,0-9.9-1-9.9-8.6
		V13.9c0-3.2,0.6-3.3,3.1-3.3c1.6,0,5.2,0,6.7,0C14.3,10.6,16.4,10.6,17.8,10.6L17.8,10.6z M31.2,40.6c8.7,0,9.7-7.2,9.7-10.3V18
		c0-0.5-0.3-0.8-0.7-0.8h-4.9c-0.4,0-0.8,0.4-0.8,0.8v12.4c0,3.9-1.2,4.7-3.1,4.7h-0.5c-1.9,0-3.1-0.8-3.1-4.7V18
		c0-0.4-0.4-0.8-0.8-0.8h-4.9c-0.4,0-0.7,0.4-0.7,0.8v12.4c0,3.1,0.9,10.3,9.7,10.3H31.2L31.2,40.6z M49.6,40.3
		c0.4,0,0.8-0.4,0.8-0.8V23.1c0-0.2,0.1-0.4,0.5-0.6c1.1-0.3,1.6-0.3,3.3-0.5c0.9-0.1,0.9-0.6,0.9-0.9v-3.6c0-0.6-0.5-0.7-0.9-0.7
		c-3.8,0-6.5,0.3-9.1,1.1c-0.9,0.3-1.2,0.7-1.2,1.3v20.2c0,0.4,0.4,0.8,0.8,0.8L49.6,40.3L49.6,40.3z M76.5,30.1v-2.8
		c0-6.6-3.8-10.5-10.1-10.5s-10.1,3.9-10.1,10.5v2.8c0,6.6,3.8,10.5,10.1,10.5S76.5,36.7,76.5,30.1z M70,27.4v2.8
		c0,4.1-2,4.9-3.6,4.9c-2.4,0-3.6-1.7-3.6-4.9v-2.8c0-4.1,2-4.9,3.6-4.9S70,23.2,70,27.4z M101.2,31.6V31c0-2.5-1.1-4.5-3.1-5.7
		c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.1-0.2,0.2-0.3c1.9-1.1,2.9-3.2,2.9-5.7v-0.7c0-3.6-1.3-8.4-12.8-8.4c-2.5,0-5.1,0.2-6.6,0.4
		c-1.6,0.2-2.2,0.9-2.2,2.6v24.7c0,1.9,0.7,2.4,2.3,2.6c1.5,0.3,4,0.5,6.7,0.5C96.9,40.6,101.2,37.6,101.2,31.6L101.2,31.6z
			M90.1,27.5c3.5,0,4.3,2,4.3,3.6v0.4c0,1.7-0.6,3.5-5.5,3.5c-0.3,0-0.9,0-1.5,0c0,0-0.4,0-0.8,0c-0.2,0-0.3-0.2-0.3-0.3v-6.9
		c0-0.2,0.2-0.3,0.3-0.3H90.1L90.1,27.5z M94.3,18.8v0.6c0,2.2-1.3,3.2-4.1,3.2h-3.5c-0.2,0-0.3-0.2-0.3-0.3v-6.4
		c0-0.2,0.1-0.3,0.3-0.3c0.7,0,1.5,0,2.1,0C92.7,15.6,94.3,16.4,94.3,18.8L94.3,18.8z M109.7,40.3c0.4,0,0.8-0.4,0.8-0.8V18.6
		c0-0.4-0.4-0.8-0.8-0.8h-4.9c-0.4,0-0.8,0.4-0.8,0.8v20.9c0,0.4,0.4,0.8,0.8,0.8H109.7L109.7,40.3z M109.8,16.3
		c0.4,0,0.8-0.4,0.8-0.8v-3.7c0-0.4-0.4-0.8-0.8-0.8h-5.2c-0.4,0-0.8,0.4-0.8,0.8v3.7c0,0.4,0.4,0.8,0.8,0.8
		C104.6,16.3,109.8,16.3,109.8,16.3z M133.4,40.3c0.3,0,0.5-0.2,0.5-0.5c0-0.1,0-0.2-0.1-0.3l-7.6-11.3c-0.1-0.1-0.1-0.3,0-0.4
		l7.6-9.7c0.1-0.2,0.2-0.3,0.2-0.4c0-0.2-0.3-0.4-0.6-0.4h-5.8c-0.4,0-0.8,0.2-1,0.6l-5.6,7.4c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1
		c-0.1,0-0.2-0.2-0.2-0.3V11.9c0-0.4-0.4-0.8-0.8-0.8h-4.8c-0.4,0-0.8,0.4-0.8,0.8v27.5c0,0.4,0.4,0.8,0.8,0.8h4.8
		c0.4,0,0.8-0.4,0.8-0.8v-8c0-0.2,0.1-0.3,0.2-0.3h0.1c0.1,0,0.2,0.1,0.3,0.2l5.1,8.4c0.3,0.5,0.5,0.5,1,0.5h6.2V40.3z M173,33.8
		c0-1.7-0.5-4.1-4.1-6.2l-5-2.9c-0.8-0.5-1.2-0.8-1.2-1.4c0-0.9,0.5-1.1,2.2-1.1c2.2,0,5.8,0.4,6.7,0.5h0.2c0.6,0,0.6-0.5,0.6-0.7
		v-3.3c0-0.5-0.3-0.8-0.7-0.9c-2-0.5-3.9-0.9-7.2-0.9c-6.8,0-8.2,3.4-8.2,6.3c0,2.6,1.2,4.6,3.6,6l5.2,3c1.1,0.6,1.5,1,1.5,1.6
		c0,1-0.4,1.5-2.4,1.5c-1.2,0-4.6-0.3-6.9-0.5H157c-0.4,0-0.7,0.3-0.7,0.7v3.3c0,0.5,0.3,0.8,0.7,0.9c1.9,0.5,4.9,0.9,7.3,0.9
		C168.5,40.6,173,38.9,173,33.8L173,33.8z M152.4,39.7c0.6-0.2,0.8-0.4,0.8-1.1v-3.1c0-0.6-0.5-0.6-0.7-0.6h-0.1
		c-1.4,0.1-4.7,0.3-7.4,0.4l0,0c-0.1,0-0.3-0.1-0.3-0.2c0-0.1,0-0.3,0.1-0.4l6.8-5.7c0,0,0,0,0.1-0.1l2.1-1.7
		c0.1-0.1,0.2-0.2,0.2-0.4v-0.1c0-1.1,0-2.2-0.2-3.3c-1.1-3.8-3.9-6.2-7.9-6.6c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-1,0
		c-0.2,0-0.3,0-0.5,0c-6,0.2-9.7,4.2-9.7,10.6v2.8c0,5,2.7,10.4,10.1,10.4C147.8,40.6,149.9,40.2,152.4,39.7L152.4,39.7z
			M144.5,22.1c0.1,0,0.1,0,0.2,0h0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.1c0.1,0,0.2,0,0.2,0.1c0.5,0.2,0.9,0.5,1.3,1
		c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0,0.1,0,0.3-0.1,0.4c-0.3,0.3-0.5,0.5-0.6,0.6l-5.6,5.1c-0.1,0.1-0.1,0.1-0.2,0.1
		h-0.1c-0.1-0.1-0.2-0.2-0.2-0.3v-3.6c0-1.7,0.5-3.1,1.5-3.8c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.1-0.1,0.2-0.1s0.1-0.1,0.2-0.1
		c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0C144.3,22.1,144.4,22.1,144.5,22.1L144.5,22.1z"/>
		<g>
		<path class="st1" d="M186.5,13.9L186.5,13.9L186.5,13.9z"/>
		<path class="st0" d="M211.1,4.4C200,0.6,187.8,6.6,184,17.8c-3.8,11.1,2.2,23.3,13.4,27.1c11.1,3.8,23.3-2.2,27.1-13.4
			C228.3,20.3,222.2,8.2,211.1,4.4z M212.7,41.9c-9.6,4.7-21.1,0.8-25.7-8.7c-4.6-9.6-0.8-21.1,8.7-25.7c9.6-4.7,21.1-0.8,25.7,8.7
			C226.1,25.7,222.2,37.2,212.7,41.9z"/>
		<path class="st0" d="M209.5,34.8L209.5,34.8C209.5,34.9,209.4,34.9,209.5,34.8c-2.3,1.5-9.8,3.7-12.8,2c-3-1.7-3.1-3.7-2.9-4.2
			c0.3-0.8,1.1-0.7,1.1-0.7c1.4,0.2,2.3,0.1,3.1,0l0,0c0.7-0.1,1.5-0.4,1.5-0.4c5.4-1.6,7.6-4.5,7.6-4.5l0.7-0.9l0,0
			c0.6-0.8,0.8-2.1,0.5-3.1c-0.4-1.9-2.4-3-4.2-2.6c-0.1,0-0.3,0.1-0.4,0.1l0,0c-4.7,1.7-7,2.3-8.6,2.3l0,0l0,0
			c-0.6,0-1.3-0.1-1.6-0.6l0,0l0,0c-0.2-0.4-0.4-1-0.2-1.9c0,0,0,0,0-0.1c1.3-3.9,3.9-6.4,8.1-7.5c6.4-1.5,13.1,2.3,14.7,8.9
			C217.8,28,213.9,32.4,209.5,34.8L209.5,34.8z"/>
		</g>
		</g>
		</g>
		</svg>`
  const svgDataUrl = `data:image/svg+xml;base64,${btoa(svg)}`
  console.info(`%c `,
    `
		background-color: #ffffff;
		background-image: url(${svgDataUrl});
		padding-top: 45px;
		padding-bottom: 45px;
		padding-left: 100%;
		margin: 0px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;
	`)
}

export const fixTwoDecimal = (x, d = 2) => (Math.round((x + Number.EPSILON) * 100) / 100).toFixed(d)
export const fixTwoDecimalNotFixed = x => Math.round((x + Number.EPSILON) * 100) / 100
export const fixThreeDecimal = x => Math.round((x + Number.EPSILON) * 1000) / 1000
export const fixRound = x => fixTwoDecimalNotFixed(fixThreeDecimal(x))

export const addrToString = (addr, pick, shop) => {
  const hasDistState = addr?.district?.name ?? addr?.district ?? addr?.state?.name ?? addr?.state ?? ``

  const districtState = hasDistState ? `(${hasDistState})` : ``
  const number = addr?.number ? `${addr?.number ?? ``},` : ``
  const floor = addr?.floor ? `${addr?.floor ?? ``},` : ``
  const extra = addr?.extra ? `${addr?.extra ?? ``},` : ``
  const townCity = addr?.town ?? addr?.city?.name ?? addr?.city
  const postalCode = addr?.zip ?? addr?.postalCode ?? ``
  const country = addr?.country?.name ?? addr?.country ?? ``

  return !addr ?
    shop ?
      `${pick} ${shop?.toLowerCase() || ``}` :
      `` :
    `${addr?.street ?? ``}, ${number} ${floor} ${extra} ${townCity}, ${postalCode} ${districtState} - ${country}`
}

export const pdrToString = pdr => {
  return `${pdr?.name}: ${pdr?.address} ${pdr?.streetNumber || ``}, ${pdr?.cityName || ``} (${pdr.postalCode})`
}

// ? fileUrl -> url descargar archivo
// ? fileName -> nombre del archivo cuando se descargue
export const downloadFile = (fileUrl, fileName, bearer = null) => {
  const headers = { "Content-Type": `application/pdf` }
  bearer && (headers[bearer.auth] = bearer.bearer)
  fetch(fileUrl, {
    method: `GET`,
    headers
  }).
    then(response => response.blob()).
    then(blob => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement(`a`)
      link.href = url
      link.setAttribute(`download`, fileName?.split(`/`).at(-1) || `file.pdf`)

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link.parentNode.removeChild(link)
    })
}
export const sorter = (arr, filter, type = `desc`) => type === `desc` ?
  arr?.sort((a, b) => {
    if (a[filter] < b[filter]) {
      return 1
    }
    if (a[filter] > b[filter]) {
      return -1
    }
    return 0
  }) :
  arr?.sort((a, b) => {
    if (a[filter] > b[filter]) {
      return 1
    }
    if (a[filter] < b[filter]) {
      return -1
    }
    return 0
  })

export const getDateDiff = date => {
  // date1 -> date to compate || date2 -> today
  const date1 = new Date(date)
  const date2 = new Date()

  // Calculate the time difference
  const timeDiff = date2.getTime() - date1.getTime()

  // Convert time to days
  const daysDiff = parseInt(timeDiff / (1000 * 3600 * 24), 10)
  return daysDiff
}
export const removeAccents = str => {
  return str.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``)
}

export const getProfileMenu = query => {
  switch (true) {
  case query?.o !== undefined:
    return types.accountButtons.orders
  case query?.wish !== undefined:
    return types.accountButtons.wishlist
  case query?.addr !== undefined:
    return types.accountButtons.addresses
  case query?.pm !== undefined:
    return types.accountButtons.payMethods
  case query?.cp !== undefined:
    return types.accountButtons.coupons
  case query?.rate !== undefined:
    return types.accountButtons.ratings
  case query?.news !== undefined:
    return types.accountButtons.newsletter
  case query?.mb !== undefined:
    return types.accountButtons.bikes
  case query?.msg !== undefined:
    return types.accountButtons.tickets
  default:
    return types.accountButtons.info
  }
}

export const cleanJson = json => {
  let tmpVal = json
  tmpVal = tmpVal.replaceAll(`[`, ``)
  tmpVal = tmpVal.replaceAll(`]`, ``)
  tmpVal = tmpVal.replaceAll(`"`, ``)
  tmpVal = tmpVal.replaceAll(`'`, ``)
  return tmpVal
}

export const openFile = fl => {
  window.open(URL.createObjectURL(fl))
}

export const toFile = (dataurl, filename) => {
  const arr = dataurl?.split(`,`)
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr?.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export const isMobile = userAgent => {
  const testResp = /iPhone|iPad|iPod|Android/i.test(userAgent)
  return testResp
}

export const isDark = hexColor => {
  const cleanColor = hexColor.replace(`#`, ``)
  const r = parseInt(cleanColor.substr(0, 2), 16)
  const g = parseInt(cleanColor.substr(2, 2), 16)
  const b = parseInt(cleanColor.substr(4, 2), 16)
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
  const average = 128
  return luminance <= average
}

export const getControlledSections = (webPages, pageName) => {
  return webPages.find(item => item.name === pageName)?.sections.reduce((acc, obj) => {
    const { name, show } = obj
    acc[name] = !!show
    return acc
  }, {})
}
