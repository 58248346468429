import connectELK from "@/utils/elk/connect"
import { languageIDHelper } from "@/utils/functions"
import { getFilters, getPathBrand } from "./catalogueBrands"
import { getChipCategories, getFilterCategory } from "@/utils/elk/functions/functions"

export const getBrands = async(slug, hostname, clientId) => {
  const { lang, id: platformId, redirection_301: redirection, business } = hostname
  const languageId = languageIDHelper(lang)
  let uri = `/${slug[0]}/`
  const redirect = await redirection.find(x => x.badUrl === uri)
  if (redirect) {
    uri = redirect.redirectionUrl
  }

  const body = {
    platformId,
    languageId,
    clientId,
    slug: uri,
    abbrElastic: business.abbrElastic
  }

  const elk = await connectELK()
  const response = await getPathBrand({ platformId, languageId, clientId, slug: uri, abbrElastic: business.abbrElastic }, elk)

  const { code, result } = await response
  if (result !== null) {
    const chips = await getChipCategories(
      body, elk, result.brand_id, result.webdata.child_category
    )
    const filters = await getFilters(business.abbrElastic, elk, result.webdata.child_category)
    const filterCat = await getFilterCategory(body, elk, 0)
    const specialList = filterCat.result
    const tmpResult = { ...result, specialList, chips: chips.result, filters: filters.result }
    return { code, result: tmpResult }
  }
  return { code, result }
}

export const getProductBrands = async({
  platformId, languageId, size, abbrElastic
}, elk) => {
  const result = await elk.search({
    index: `${abbrElastic}_product_elk`,
    _source: false,
    query: {
      bool: {
        must: [{ match: { platform_id: platformId }}, { match: { language_id: languageId }}],
        filter: [
          {
            term: {
              is_deleted: 0
            }
          }
        ]
      },
    },
    aggs: {
      brands: { terms: { field: `brand_id`, size }}
    }
  },
  { meta: true })
  return {
    code: result.statusCode,
    result: result.body.aggregations.brands.buckets.map(x => x.key).flat(),
  }
}

export const brandsELK = async({
  platformId, languageId, size, abbrElastic, brands
}, elk) => {
  const should = brands.map(x => ({ term: { brand_id: x }}))
  const result = await elk.search({
    index: `${abbrElastic}_web_brands_elk`,
    from: 0,
    size,
    sort: [
      {
        "name.keyword": `asc`
      }
    ],
    query: {
      bool: {
        must: [{ match: { platform_id: platformId }}, { match: { language_id: languageId }}],
        minimum_should_match: 1,
        should,
        filter: [
          {
            term: {
              is_deleted: 0
            }
          }
        ]
      },
    },
  },
  { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.total.value > 0 ? result.body.hits.hits?.map(x => x._source) : {},
  }
}

export const handleBrand = async(
  platformId, languageId, abbrElastic, size = 1000
) => {
  const elk = await connectELK()
  const result = await getProductBrands({ abbrElastic, platformId, languageId, size }, elk)
  const response = await brandsELK({ abbrElastic, platformId, languageId, size, brands: result.result }, elk)
  return response
}
