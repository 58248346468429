'use client'
import { getCurrencies } from "@/app/elastic/currencies"
import { getDefaultShipping, getTexts } from "@/app/elastic/tools"
import { types } from "@/types"
import { useEffect, useReducer } from "react"
import { initObj } from "./Obj"
import { ToolsContext } from "./ToolsContext"
import { toolsReducer } from "./toolsReducer"

export const ToolsProvider = ({ children, ...props }) => {
  const { hostname } = props
  const { colors, texts } = hostname
  const { platformTypeId, langId, language } = hostname
  const [ state, dispatch ] = useReducer(toolsReducer, initObj)
  const handleColors = payload => {
    dispatch({ type: types.tools.colors, payload })
  }
  const handleTexts = payload => {
    dispatch({ type: types.tools.texts, payload })
  }
  const handleCurrencies = payload => {
    dispatch({ type: types.tools.currencies, payload })
  }
  const handleShipping = payload => {
    dispatch({ type: types.tools.shipping, payload })
  }
  const execute = async() => {
    handleColors(colors)
    await Promise.all([
      getTexts([], { langId, platformTypeId }),
      getCurrencies(),
      getDefaultShipping(language)
    ]).then(([ pageTexts, currencies, ship ]) => {
      handleTexts(pageTexts)
      handleCurrencies(currencies)
      handleShipping(ship)
    })
  }
  useEffect(() => {
    execute()
  }, [])
  return (<ToolsContext.Provider
    value={{
      tools: { ...state },
    }}>
    { children }
  </ToolsContext.Provider>)
}
