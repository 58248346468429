export const grid = {
  gridHome: `gridHome`,
  gridContainerHome: `gridContainerHome`,
  gridContainerItemHome: `gridContainerItemHome`,
  gridItemHome: `gridItemHome`,
  gridItemFlex: `gridItemFlex`,
  gridContainerLogin: `gridContainerLogin`,
  gridItemLogin: `gridItemLogin`,
  gridMenuContainer: `gridMenuContainer`,
  gridMenuBody: `gridMenuBody`,
  gridSubMenuBody: `gridSubMenuBody`,
  gridLegal: `gridLegal`,
  gridsItem: `gridsItem`,
  gridCookie: `gridCookie`,
  gridFilter: `gridFilter`,
}
