export const colors = {
  main: `#003366`,
  mainImportant: `#003366 !important`,
  tild: `#5696FA`,
  secondary: `#2b6195`,
  white: `#FFFFFF`,
  whiteImp: `#FFFFFF !important`,
  dark: `#000000`,
  dark2: `#1E1E1E`,
  color: `#1E1E1E`,
  grey: `#ffd09b`,
  darkGrey: `#555555`,
  darkGrey2: `#858585`,
  lightGrey: `#F2F2F2`,
  lightGrey2: `#e0e0e0`,
  lightGrey3: `#c2c2c2`,
  orange: `#FD9519`,
  lightBlue: `#2b6195`,
  lightBlueI: `#2b6195 !important`,
  SBlue: `#477294`,
  PriceTagBlue: `#5a93fb`,
  GreenAv: `#1cb31c`,
  YellowAv: `#ff9900`,
  RedAv: `#ff0000`,
  greenBtn: `#1bbe66`,
  yellowBtn: `#ff9900`,
  redBtn: `#ff3f3f`,
  muiErr: `#d50000`,
  novelty: `#FD9519`,
  discount: `#2b6195`,
  starEnable: `#FD9519`,
  starDisabled: `#E8E8E8`,
  brand: `#858585`,
  pvp: `#858585`,
  cost: `#2b6195`,
  photoBackground: `#F2F2F2`,
  photoGrey: `#F2F2F2`,
  infoBackground: `#E9EDF3`,
  oney: `#84BD06`,
  filter: `#DDDDDD`,
  borderProduct: `#cfcfcf`,
  backGrey: `#f2f2f2 !important`,
  inputBlack: `#00000030`,
  FadedBlack: `#00000050`,
  FadedBlack2: `#0000001c`,
  cookies: {
    title: `#1E1E1E`,
    text: `#858585`,
    active: {
      background: `#1E1E1E`,
      color: `#FFFFFF`
    },
    deactivated: {
      background: `#F2F2F2`,
      color: `#858585`
    }
  },
  buttonInfo: {
    background: `#F2F2F2`,
    color: `#858585`
  },
  boxInfo: {
    background: `#FFFFFF`,
    color: `#000000`
  },
  pagination: {
    active: {
      background: `#1E1E1E`,
      color: `#FFFFFF`
    },
    deactivated: {
      background: `#F2F2F2`,
      color: `#858585`
    }
  },
  footer: {
    background: `#F2F3F5`,
    color: `#1E1E1E`,
    subColor: `#555555`,
    link: `#2b6195`
  },
  toTop: `#d5d6d6`,
  toTopHover: `#787878`,
  success: `#4caf50`,
  warning: `#ff9800`,
  error: `#f44336`,
  teal: `#009688`,
  logoFooter: `#1e1e1e`,
  gold: `#faaf00`,
  logo: null,
  secondaryRgb: `rgb(0 51 102)`,
  mainContrast: `#003366`,
  logoSecondary: `#003366`,
  logoMain: `#ffffff`,
  product: {
    carts: `#FF4F4F`,
    visits: `#F26C4F`
  },
  infoHomeText: `black`,
  infoHomeBack: `#F2F3F5`
}
