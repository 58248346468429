/* eslint-disable no-extra-parens */
import { types } from "@/types"
import { constants, rulesForm } from "@/utils/global"
import { isEmpty, isLength } from "validator"
import isEmail from "validator/lib/isEmail"
import isURL from "validator/lib/isURL"

// Importamos de nuestro archivo de constantes las variables que vamos necesitando para guardar el error, evaluar la información, etc.
const {
  ERROR__INPUT, REG_EXP, REG_EXP_PHONE, MAX_SIZE
} = constants
/**
 * Se encarga de validar la información que contiene el input
 *
 * @param {*} param0 -> {type} Tipo de campo
 * @param {*} param0 -> {input} Valor del input dentro del evento
 *
 * @returns -> Retorna false si no hay error, un array si hay error
 */
export const handleValidate = ({ type, input }) => {
  let msgError = false

  // el switch se encarga de gestionar los distintos tipos a validar
  switch (type) {
  /**
           * Reglas input USER
           * No puede estar vacio y su longitud no puede ser menor a 6 caracteres
           */
  case types.input.user:
  case types.input.dir.street:
    if (!isEmpty(input?.trim()) && !isLength(input?.trim(), {
      min: 2,
      max: undefined
    })) {
      msgError = ERROR__INPUT.errorUser
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input COMPANY
     * No puede estar vacio y su longitud no puede ser menor a 4 caracteres
     */
  case types.input.company:
    if (!isEmpty(input) && !isLength(input, {
      min: 4,
      max: undefined
    })) {
      msgError = ERROR__INPUT.errorCompany
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input CONTACT
     * No puede estar vacio y su longitud no puede ser menor a 4 caracteres
     */
  case types.input.contact:
    if (!isEmpty(input) && !isLength(input, {
      min: 4,
      max: undefined
    })) {
      msgError = ERROR__INPUT.errorContact
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input WEB
     * No puede estar vacio y debe tener el formato de una URL
     */
  case types.input.web:
    if (!isEmpty(input) && !isURL(input)) {
      msgError = ERROR__INPUT.errorWeb
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input NIF
     * No puede estar vacio y debe tener el formato de una NIF/CIF valido
     * URL's para ver las condiciones de CIF/NIF de cada País:
     * https://marosavat.com/es/formatos-de-nif-en-europa/
     * http://www.validar-nif-iva.es/
     *
     * ES: X(-|\\.)?0?\\d{7}(-|\\.)?[A-Z]|[A-Z](-|\\.)?\\d{7}(-|\\.)? [0-9A-Z]|\\d{8}(-|\\.)?[A-Z]
     * FR: [0-3]\\d{12}?|[0-9A-Z][0-9A-Z]\\d{9}
     * DE: \\d{9}
     * IT: \\d{11}
     * PT: \\d{9}
     * UK: \\d{9}
     */
  case types.input.nif:
  case types.input.vatNumber:
    if (!isEmpty(input) && !new RegExp(REG_EXP).exec(input)) {
      msgError = ERROR__INPUT.errorNif
    } else if (isEmpty(input)) {
      msgError = ERROR__INPUT.errorNif
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input Phone
     * No puede estar vacio y debe tener el formato de un telefono valido
     * XXXXXXXXX | XXX-XXX-XXX   | XXX XXX XXX
     * XXXXXXXXX | XXX-XX-XX-XX  | XXX XX XX XX
     * XXXXXXXXX | X-XX-XX-XX-XX | X XX XX XX XX
     */
  case types.input.phone:
    if (!isEmpty(input) && !new RegExp(REG_EXP_PHONE).exec(input)) {
      msgError = ERROR__INPUT.errorPhone
    } else if (isEmpty(input)) {
      msgError = ERROR__INPUT.errorPhone
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input Email
     * No puede estar vacio y debe tener el formato de un email valido
     */
  case types.input.email:
  case types.input.userName:
    if (!isEmpty(input?.trim()) && !isEmail(input?.trim())) {
      msgError = ERROR__INPUT.errorEmail
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input Password
     * No puede estar vacio y debe tener una longitud minima de 6 caracteres
     */
  case types.input.pass:
  case types.input.recoverPass:
    if (!isEmpty(input?.trim()) && !isLength(input?.trim(), {
      min: 6,
      max: undefined
    })) {
      msgError = ERROR__INPUT.errorPass
    } else {
      msgError = false
    }
    break
    /**
     * Reglas input Upload File
     * No puede ser mayor de 2MB
     */
  case types.input.adjunt:
    if (input.size > MAX_SIZE) {
      msgError = ERROR__INPUT.errorAttach
    } else {
      msgError = false
    }
    break

  default:
    msgError = false
    break
  }
  return msgError
}

export const validations = {
  [types.input.name]: (input, errorTexts) => !!input && (input?.trim() === `` || isEmpty(input?.trim()) || !isLength(input, rulesForm[types.input.name])) ? errorTexts.errorContact : undefined,
  [types.input.lastName]: (input, errorTexts) => undefined,
  [types.input.email]: (input, errorTexts) => input !== undefined && (input?.trim() === `` || isEmpty(input?.trim()) || !isEmail(input?.trim())) ? errorTexts.errorEmail : undefined,
  [types.input.userName]: (input, errorTexts) => !!input && (input?.trim() === `` || isEmpty(input?.trim()) || !isEmail(input?.trim())) ? errorTexts.errorEmail : undefined,
  [types.input.phone]: (input, errorTexts) => !!input && (input?.trim() === `` || (!!input && !isEmpty(input) && !new RegExp(REG_EXP_PHONE).exec(input))) ? errorTexts.errorPhone : undefined,
  [types.input.pass]: (input, errorTexts) => input !== undefined && (input?.trim() === `` || (!isEmpty(input?.trim()) && !isLength(input?.trim(), rulesForm[types.input.pass]))) ? errorTexts.errorPass : undefined,
  [types.input.recoverPass]: (input, errorTexts) => !!input && (input?.trim() === `` || (!isEmpty(input?.trim()) && !isLength(input?.trim(), rulesForm[types.input.pass]))) ? errorTexts.errorPass : undefined,
  [types.input.vatNumber]: (input, errorTexts) => (!isEmpty(input) && !new RegExp(REG_EXP).exec(input)) ? errorTexts.errorNif : undefined,
  [types.input.dir.name]: (input, errorTexts) => isEmpty(input?.trim()) || !isLength(input, rulesForm[types.input.dir.name]) ? errorTexts.errorContact : undefined,
  [types.input.dir.street]: (input, errorTexts) => (!isEmpty(input) && !isLength(input, rulesForm[types.input.dir.street])) ? errorTexts.errorStreet : undefined,
  [types.input.dir.number]: (input, errorTexts) => input === null || (!!input.toString && !isEmpty(input.toString()) && (input.toString()?.trim() === `` || (!!input.toString() && !isEmpty(input?.toString()) && !isLength(input?.toString(), rulesForm[types.input.dir.number])))) ? errorTexts.errorNumber : undefined,
  [types.input.dir.floor]: (input, errorTexts) => (!!input && input !== `` && !isEmpty(input) && !isLength(input, rulesForm[types.input.dir.floor])) ? errorTexts.errorFloor : undefined,
  [types.input.dir.extra]: (input, errorTexts) => (!!input && input !== `` && !isEmpty(input) && !isLength(input, rulesForm[types.input.dir.extra])) ? errorTexts.errorExtra : undefined,
  [types.input.dir.comment]: (input, errorTexts) => (!!input && input !== `` && !isEmpty(input) && !isLength(input, rulesForm[types.input.dir.comment])) ? errorTexts.errorComment : undefined,
  [types.input.dir.country]: (input, errorTexts) => undefined,
  [types.input.dir.zip]: (input, errorTexts) => (!isEmpty(input?.trim()) && !isLength(input?.trim(), rulesForm[types.input.dir.zip])) ? `` : undefined,
  [types.input.dir.state]: (input, errorTexts) => undefined,
  [types.input.dir.district]: (input, errorTexts) => undefined,
  [types.input.dir.city]: (input, errorTexts) => undefined,
  [types.input.dir.town]: (input, errorTexts) => undefined,
  [types.input.dir.default_invoice_address]: (input, errorTexts) => undefined,
  [types.input.dir.default_shipping_address]: (input, errorTexts) => undefined,
  [types.input.order]: (input, errorTexts) => undefined,
  [types.input.message]: (input, errorTexts) => undefined,
  [types.input.rating]: (input, errorTexts) => undefined,
  [types.input.title]: (input, errorTexts) => (!isEmpty(input?.toString()) && !isLength(input?.toString(), rulesForm[types.input.title])) ? errorTexts.errorReviewTitle : undefined,
  [types.input.comments]: (input, errorTexts) => !!input && (input?.trim() === `` || (!!input && !isEmpty(input?.toString()) && !isLength(input?.toString(), rulesForm[types.input.comments]))) ? errorTexts.errorReviewComment : undefined,
  [types.input.pros]: (input, errorTexts) => !!input && (input?.trim() === `` || (!!input && !isEmpty(input?.toString()) && !isLength(input?.toString(), rulesForm[types.input.pros]))) ? errorTexts.errorReviewPros : undefined,
  [types.input.cons]: (input, errorTexts) => !!input && (input?.trim() === `` || (!!input && !isEmpty(input?.toString()) && !isLength(input?.toString(), rulesForm[types.input.cons]))) ? errorTexts.errorReviewCons : undefined,
  address_id: (input, errorTexts) => undefined,
  user_id: (input, errorTexts) => undefined,
  partner_id: (input, errorTexts) => undefined,
  attn: (input, errorTexts) => undefined,
  category: (input, errorTexts) => undefined,
  legal: (input, errorTexts) => undefined,
}
