export const config = {
  HOSTNAME: `eurobikes.com`,

  // URL THUMBS
  URL_THUMB: process.env.NEXT_PUBLIC_URL_THUMB,
  URL_LINK: process.env.NEXT_PUBLIC_URL_LINK,

  // GOOGLE API KEY para google.maps.place
  GOOGLE_KEY_API: process.env.NEXT_PUBLIC_GOOGLE_KEY_API,

  // 7 dias de control para mantener la sesion 
  _CONTROL_DAYS: 604400,

  // Nombre de cookies internas
  COOKIES: {
    _WLYR_: process.env.WLYR ?? process.env.NEXT_PUBLIC_WLYR,
    _WLYR_MENU: process.env.WLYR_MENU ?? process.env.NEXT_PUBLIC_WLYR_MENU,
    _WLYR_ORDER: process.env.WLYR_ORDER ?? process.env.NEXT_PUBLIC_WLYR_ORDER,
    COOKIES_POLICY: `cookies`,
    COOKIES_AD_USER_DATA: `ad_user_data`,
    COOKIES_AD_PERSONALIZATION: `ad_personalization`,
    LAST_SEARCH: `last-search`,
  },

  // Rutas del path para navegar
  PATH: {
    HOME: process.env.NEXT_PUBLIC_HOME,
    LOGIN: process.env.NEXT_PUBLIC_LOGIN,
    REGISTER: process.env.NEXT_PUBLIC_REGISTER,
    RECOVERY_PASSWORD: process.env.NEXT_PUBLIC_RECOVERY_PASSWORD,
    RESET_PASSWORD: `${process.env.NEXT_PUBLIC_RESET_PASSWORD}`,
    ACCOUNT: process.env.NEXT_PUBLIC_ACCOUNT,
    CART: process.env.NEXT_PUBLIC_CART,
    PAYMENT_OK: process.env.NEXT_PUBLIC_PAYMENT_OK,
    PAYMENT_ERP: process.env.NEXT_PUBLIC_PAYMENT_ERP,
    PAYMENT_ERROR: process.env.NEXT_PUBLIC_PAYMENT_ERROR,
  },

  // FONT_MOBILE: fnt,

  CATALOGUE_PAGES: {
    PRODUCT: `product`,
    BIKE: `bike`,
    CATALOGUE: `catalogue`,
    BRAND: `brand`,
    SEARCH: `search`,
    BRANDS: `brands`,
  },

  // Inhabilita propiedades de los navagadores en determinados terminales
  iOS: typeof navigator !== `undefined` && /iPad|iPhone|iPod|safari|crios|samsung|xiaomi|chrome/.test(navigator.userAgent),
}
