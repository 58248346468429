/* eslint-disable no-param-reassign */
import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"
import { source, types } from "@/types"
import connectELK from "@/utils/elk/connect"
import { fixTwoDecimal, sorter } from "@/utils/functions"
import { constructProduct } from "@/utils/functions/products"
import { constants } from "@/utils/global"

const getCatalogue = async(body, elk) => {
  const { slug } = body
  const result = await elk.search(slug, { meta: true })
  return {
    code: result.statusCode,
    result: {
      data: result.body.hits.hits?.map(x => x._source) || [],
      attr: result.body.aggregations.attrs.buckets.map(x => x.key).flat(),
      brand: result.body.aggregations.brands.buckets.map(x => x.key).flat(),
      categories: result.body.aggregations.categories.buckets.map(x => x.key).flat(),
      count: result.body.aggregations.count.value,
      maxPrice: fixTwoDecimal(result.body.aggregations.maxPrice.value)
    },
  }
}

export const getLabels = async(body, elkParams = null) => {
  const elk = elkParams !== null ? elkParams : connectELK()
  const { platformId, languageId, abbrElastic } = body
  const _source = source.labels
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0,
      },
    },
  ]
  const obj = {
    index: `${abbrElastic}_web_menu_elk`,
    _source,
    size: 1000,
    query: {
      bool: {
        must: [
          {
            multi_match: {
              query: `1`,
              fields: [
                `special`, `blackfriday`, `outlet`, `news`
              ]
            }
          },
        ],
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

export default async function getProdList(body) {
  const elk = connectELK()
  let catalogue = {}
  const { code, result } = await getCatalogue(body, elk)
  if (result.count > 0 && result.data.length > 0) {
    const datas = []
    const sendData = { url: `/product-cost/${body.clientId}`, encrypt: result.data.map(x => x.product_id), type: `POST` }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)

    const { message } = await apiCall(encriptedData)

    result.data.forEach((item, idx) => {
      const tmp = message.find(x => x.id === item.product_id)
      item[tmp.items.type] = tmp.items
      item.images = [tmp.items.images]
      datas.push(item)
      if (idx === result.data.length - 1) {
        catalogue = {
          code,
          result: {
            attr: result.attr,
            maxPrice: result.maxPrice,
            count: result.count,
            brand: result.brand,
            categories: result.categories,
            data: constructProduct(
              body.slug.sort[0]?.sequence !== undefined ? sorter(datas, types.sorter.sequence) : datas, false, false, true
            ).items
          }
        }
      }
    })
  } else {
    catalogue = {
      code,
      result: {
        attr: [],
        maxPrice: null,
        count: 0,
        brand: [],
        data: []
      }
    }
  }
  return catalogue
}

export function getCatalogueInfo(
  returnList, motoVO, page, limit
) {
  if (motoVO) {
    return {
      catalogue: returnList.catalogue,
      brands: returnList.brands,
      categories: returnList.categories || [],
      result: returnList.count,
      urlResults: returnList.count,
      maxPrice: returnList.maxPrice,
      attr: returnList.attr,
      motovo: motoVO,
      page,
      limit
    }
  }
  return {
    catalogue: returnList.result.data,
    brands: returnList.result.brand,
    categories: returnList.result.categories || [],
    result: returnList.result.count,
    urlResults: returnList.result.count,
    maxPrice: returnList.result.maxPrice,
    attr: returnList.result.attr,
    labels: returnList.result.labels,
    motovo: motoVO,
    page,
    limit
  }
}

export function getInitialCatalogue(motoVO, page, limit) {
  return {
    catalogue: [],
    brands: [],
    categories: [],
    result: 0,
    urlResults: 0,
    maxPrice: constants.PRICE_RANGE.max,
    attr: [],
    labels: [],
    motovo: motoVO,
    page,
    limit
  }
}
