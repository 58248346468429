import { source } from "@/types"
import connectELK from "@/utils/elk/connect"
import { getFilterCategory } from "@/utils/elk/functions/functions"
import { getFilterBrand } from "./catalogueBrands"

const getChipCategories = async(
  body, elk, brandId, categories
) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [
    { "level2.keyword": `desc` },
    { "info.sequence": `desc` },
    { "info.h1.keyword": `asc` }
  ]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { category_id: x }}))
  brandId !== null && should.push({ match: { [`info.brands`]: brandId }})
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const obj = {
    index: `${abbrElastic}_web_menu_elk`,
    size: 1000,
    sort,
    _source: source.chipCategories,
    query: {
      bool: {
        minimum_should_match,
        should,
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

export const getFilters = async(
  body, elk, categories, metaMoto, searchMoto = false
) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [{ sequence: { order: `desc` }}, { attribute_id: { order: `asc` }}]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { categories: x }}))
  if (metaMoto) {
    sort.unshift({ meta_moto: { order: `desc` }})
    should.unshift({ match: { meta_moto: 1 }})
  }
  if (searchMoto) {
    should.unshift({ match: { motorbike: 1 }})
  }
  const obj = {
    index: `${abbrElastic}_web_filters_elk`,
    sort,
    from: 0,
    size: 100,
    _source: [
      `name`, `translations`, `attribute_id`, `attributes`, `sequence`, `meta_moto`, `motorbike`
    ],
    query: {
      bool: {
        minimum_should_match,
        should,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

const getChipBrands = async(body, elk, brands) => {
  const { platformId, languageId, abbrElastic } = body
  const minimum_should_match = 1
  const should = brands.map(x => ({ match: { brand_id: x }}))
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const obj = {
    index: `${abbrElastic}_web_brands_elk`,
    size: 1000,
    _source: source.chipBrands,
    query: {
      bool: {
        minimum_should_match,
        should,
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

const getProductSearch = async(body, elk) => {
  const { search, platformId, languageId, abbrElastic } = body
  const _source = source.productCard
  const tmpSearch = search.split(` `)
  const minimum_should_match = 0
  const filter = [
    { term: { is_deleted: 0 }},
    { term: { platform_id: platformId }},
    { term: { language_id: languageId }},
  ]
  const getFields = [
    `product_id.keyword^2`,
    `webdata.title^2`,
    `webdata.h1^2`,
    `webdata.h2^0.1`,
    `attributes.attr_name^1.5`,
    `webdata.prefix^1.2`,
    `variant.brand_name^2`,
    `variant.ean^2`,
    `webdata.dimensions^1.5`,
    `variant.reference^1`,
    `breadcrumbs.h1^0.3`,
    `breadcrumbs.path^0.3`,
    `meta_moto.meta^0.5`,
  ]

  const multi_match = {
    query: tmpSearch.map(x => {
      if (x.match(/^[a-zA-Z0-9]+$/)) {
        return `*${x}*`
      }
      const replaced = x.replace(/[-[\]{}()*+?.,\\^$|#\s/]/g, `\\$&`)
      return replaced
    }).join(` `),
    fields: getFields,

    // type: `most_fields`,
    minimum_should_match: tmpSearch.length,
    fuzziness: `auto`,
    fuzzy_prefix_length: 1,
    default_operator: `or`,
    analyze_wildcard: true,
    analyzer: `lowercase_analyzer`
  }
  const must = [{ query_string: multi_match }]
  const obj = {
    index: `${abbrElastic}_product_elk`,
    sort: [{ _score: { order: `desc` }}],
    _source,
    query: {
      function_score: {
        boost: 20,
        max_boost: 100,
        score_mode: `multiply`,
        query: {
          bool: {
            must,
            minimum_should_match,
            filter
          }
        },
      }
    },
    aggs: {
      count: {
        value_count: {
          field: `product_id`
        }
      },
      categories: {
        terms: {
          field: `categories`,
          size: 35
        }
      },
      brands: {
        terms: {
          field: `brand_id`,
          size: 35
        }
      }
    }
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: {
      data: result.body.hits.total.value > 0 ? result.body.hits.hits?.map(x => x._source) : {},
      count: result.body.aggregations.count.value,
      categories: result.body.aggregations.categories.buckets.map(x => x.key),
      brands: result.body.aggregations.brands.buckets.map(x => x.key)
    }
  }
}

export default async function getSearchData(body) {
  const elk = await connectELK()
  let chips = []
  const response = await getProductSearch(body, elk)
  if (response.result?.count > 0) {
    chips = await getChipCategories(
      body, elk, null, response.result.categories
    )
    const [ brands, filters, filterCat ] = await Promise.all([
      getFilterBrand(body, elk, response.result.brands),
      getFilters(
        body, elk, chips.result.map(x => x.info.id).flat(), false
      ),
      getFilterCategory(body, elk, 0)
    ])
    return {
      code: response.code,
      result: {
        brands: brands?.result || [],
        chips: chips?.result || [],
        filters: filters.result,
        count: response.result.count,
        specialList: filterCat.result,
      }
    }
  }
  return {
    code: response.code,
    result: {
      brands: [],
      chips: [],
      filters: [],
      count: [],
      specialList: [],
    }
  }
}
