export const acc = {
  accClass: `accClass`,
  gridAccMenu: `gridAccMenu`,
  gridAccItem: `gridAccItem`,
  accButton: `accButton`,
  accMenu: `accMenu`,
  accBox: `accBox`,
  accDesc: `accDesc`,
  accUser: `accUser`,
  accDfltBtn: `accDfltBtn`,
  accCheck: `accCheck`,
}
