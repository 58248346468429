export const getPathCategory = async(body, elk) => {
  const {
    platformId, languageId, slug, abbrElastic
  } = body
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0,
      },
    },
  ]
  const obj = {
    index: `${abbrElastic}_web_menu_elk`,
    _source: [
      `info`, `banner`, `level`, `path`, `breadcrumbs`, `parent.url`
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              "alias.keyword": slug,
            },
          },
        ],
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source)?.at(0) || null,
  }
}

export const getChipCategories = async(
  body, elk, level, parent, searchMotos
) => {
  const { platformId, languageId, abbrElastic } = body
  let sort = {}

  const _source = [ `info.h1`, `path` ]
  searchMotos && _source.push(`info.id`)
  const searchLevel = level === `level2` ? `level3` : `level2`
  const father = { [level === `level2` ? `parent.id` : `father`]: parent }
  switch (level) {
  case `level2`:
    sort = { [`info.h1.keyword`]: `asc` }
    break
  default:
    sort = { [`info.sequence`]: `desc` }
    break
  }

  const filter = [
    searchMotos ?
      { term: { meta_moto: 1 }} :
      {
        term: father,
      },
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0,
      },
    },
  ]
  const obj = {
    index: `${abbrElastic}_web_menu_elk`,
    sort: [sort],
    _source,
    size: 1000,
    query: {
      bool: {
        must: [
          {
            term: {
              [searchLevel]: 1,
            },
          },
        ],
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

export const getFilterBrand = async(
  body, elk, categories, meta = false
) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [{ "webdata.h1.keyword": `asc` }]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { [meta ? `brand_id` : `webdata.child_category`]: x }}))
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0,
      },
    },
  ]
  const obj = {
    index: `${abbrElastic}_web_brands_elk`,
    sort,
    size: 1000,
    _source: [
      `webdata.h1`, `webdata.url`, `brand_id`, `name`
    ],
    query: {
      bool: {
        // minimum_should_match,
        // should,
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}
