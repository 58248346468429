export const utils = {
  bikeCard: `bikeCard`,
  bikeCardArea: `bikeCardArea`,
  productCard: `productCard`,
  bikeCardContent: `bikeCardContent`,
  productCardC: `productCardC`,
  formInputs: `formInputs`,
  checkLabel: `checkLabel`,
  headerTips: `headerTips`,
  addrItem: `addrItem`,
  itemOrder: `itemOrder`,
  modal: `modal`,
  contactField: `contactField`,
  page: `page`,
  lineCard: `lineCard`,
  lineCardAction: `lineCardAction`,
  lineCardActionDelete: `lineCardActionDelete`,
}
