import { types } from "@/types"

export const rulesForm = {
  [types.input.name]: {
    min: 4,
    max: undefined
  },
  [types.input.dir.zip]: {
    min: 2,
    max: undefined
  },
  [types.input.pass]: {
    min: 6,
    max: undefined
  },
  [types.input.dir.name]: {
    min: 4,
    max: undefined
  },
  [types.input.dir.street]: {
    min: undefined,
    max: 50
  },
  [types.input.dir.number]: {
    min: undefined,
    max: 5
  },
  [types.input.dir.floor]: {
    min: undefined,
    max: 2
  },
  [types.input.dir.extra]: {
    min: undefined,
    max: 150
  },
  [types.input.dir.comment]: {
    min: undefined,
    max: 150
  },
  [types.input.title]: {
    min: undefined,
    max: 45
  },
  [types.input.comments]: {
    min: 5,
    max: 225
  },
  [types.input.pros]: {
    min: undefined,
    max: 120
  },
  [types.input.cons]: {
    min: undefined,
    max: 120
  },
}
