export const getPathBrand = async(body, elk) => {
  const {
    platformId, languageId, slug, abbrElastic
  } = body
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const obj = {
    index: `${abbrElastic}_web_brands_elk`,
    _source: [
      `webdata`,
      `hreflang`,
      `brand_id`,
      `breadcrumbs`,
      `path`,
      `banner`,
      `name`
    ],
    query: {
      bool: {
        must: [
          {
            match: {
              "alias.keyword": slug
            },
          }
        ],
        filter,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source)?.at(0) || null,
  }
}

export const getFilters = async(abbrElastic, elk, categories) => {
  const sort = [{ sequence: { order: `desc` }}, { attribute_id: { order: `asc` }}]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { categories: x }}))
  should.push({ match: { meta_moto: 0 }})
  should.push({ match: { motorbike: 0 }})
  const obj = {
    index: `${abbrElastic}_web_filters_elk`,
    sort,
    from: 0,
    size: 100,
    _source: [
      `name`,
      `translations`,
      `attribute_id`,
      `attributes.id`,
      `attributes.code`,
      `attributes.sequence`,
      `attributes.translations`,
      `meta_moto`,
      `sequence`
    ],
    query: {
      bool: {
        must: [
          { match: { meta_moto: 0 }},
          { match: { motorbike: 0 }}
        ],
        minimum_should_match,
        should,
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}

export const getFilterBrand = async(body, elk, categories) => {
  const { platformId, languageId, abbrElastic } = body
  const sort = [{ "webdata.h1.keyword": `asc` }]
  const minimum_should_match = 1
  const should = categories.map(x => ({ match: { [`webdata.child_category`]: x }}))
  const filter = [
    {
      term: {
        platform_id: platformId,
      },
    },
    {
      term: {
        language_id: languageId,
      },
    },
    {
      term: {
        is_deleted: 0
      }
    }
  ]
  const obj = {
    index: `${abbrElastic}_web_brands_elk`,
    sort,
    size: 1000,
    _source: [
      `webdata.h1`,
      `webdata.url`,
      `brand_id`,
      `name`
    ],
    query: {
      bool: {
        minimum_should_match,
        should,
        filter
      },
    },
  }
  const result = await elk.search(obj, { meta: true })
  return {
    code: result.statusCode,
    result: result.body.hits.hits?.map(x => x._source),
  }
}
