import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"

export const infoSitemap = async(path = ``) => {
  const sendData = {
    url: `/sitemap/info${path}`,
    encrypt: ``,
    type: `GET`
  }
  const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
  const { error, message } = await apiCall(encriptedData)
  return {
    error,
    message
  }
}

export const constructorSitemap = async(
  Readable, SitemapStream, streamToPromise, res, sitemap, host
) => {
  const fixedHost = host.includes(`localhost`) ? `http://${host}` : `https://${host}`
  const sitemapStream = new SitemapStream({
    hostname: fixedHost,
  })
  res.writeHead(200, {
    "Content-Type": `application/xml`,
  })
  const xmlString = await streamToPromise(Readable.from(sitemap).pipe(sitemapStream)).then(data => data.toString())
  return xmlString
}
