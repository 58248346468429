export const menu = {
  menuDrawer: `menuDrawer`,
  menuList: `menuList`,
  mobileMenu: `mobileMenu`,
  sectionMenu: `sectionMenu`,
  closeMobileMenu: `closeMobileMenu`,
  forwardMenu: `forwardMenu`,
  drawer: `drawer`,
  drawerSwipeable: `drawerSwipeable`,
}
