/* eslint-disable require-atomic-updates */
import connectELK from "@/utils/elk/connect"
import { getChipCategories, getFilterBrand, getPathCategory } from "./cataloguePath"
import { getFilterCategory, getFilters } from "@/utils/elk/functions/functions"
import { getControlledSections } from "@/utils/functions"

export const getCatalogue = async(slug, hostname, clientId) => {
  const {
    langId: languageId, id: platformId, redirection_301: redirection, business, webPages
  } = hostname

  const sectionFilters = getControlledSections(webPages, `filters`)

  let uri = slug.length > 2 ? `/${slug.slice(0, 2).join(`/`)}/` : `/${slug.join(`/`)}/`
  const redirect = await redirection.find(x => x.badUrl === uri)
  if (redirect) {
    uri = redirect.redirectionUrl
  }

  const body = {
    platformId,
    languageId,
    clientId,
    slug: uri,
    abbrElastic: business.abbrElastic
  }

  const elk = await connectELK()
  let response = await getPathCategory({ platformId, languageId, clientId, slug: uri, abbrElastic: business.abbrElastic }, elk)

  if (response.result === null) {
    uri = `/${slug.slice(0, 1).join(`/`)}/`
    response = await getPathCategory({ platformId, languageId, clientId, slug: uri, abbrElastic: business.abbrElastic }, elk)
  }

  const { code, result } = await response

  if (result !== null) {
    let chips = []
    if (result.info.id !== 234) {
      const { level } = await result
      const tmpLevel = Object.entries(level).find(x => x[1] === 1)[0]
      chips = [ `level1`, `level2` ].includes(tmpLevel) && result.info.search_category !== null ?
        await getChipCategories(
          body, elk, tmpLevel, result.info.id
        ) :
        { result: [{ info: { h1: result.info.h1 }, path: result.path }]}
    } else {
      chips = await getChipCategories(
        body, elk, null, null, true
      )
    }
    const meta = result.info.id === 234
    const filters = await getFilters(
      body, elk, meta ? chips.result.map(x => x.info.id).flat() : result.info.search_category || [result.info.id], result.info.id === 234, result.info.id === 227
    )
    const tmpFilter = meta ? [...new Set(filters.result.find(x => x.meta_moto === 1).attributes.map(x => x.brand_id))] : []

    const brands = sectionFilters?.brandFilter ? await getFilterBrand(
      body, elk, meta ? tmpFilter : result.info.search_category || [result.info.id], meta
    ) : { result: []}

    let specialList = []
    if (result.info.black_friday === 1 || result.info.outlet === 1 || result.info.news === 1 || result.info.special === 1 || result.info.id === 235) {
      const filterCat = await getFilterCategory(body, elk, result.info.id)
      specialList = filterCat.result
    }

    const tmpResult = {
      ...result, specialList, chips: chips.result, filters: filters.result, brands: !meta ? brands.result : brands.result.map(x => {
        return tmpFilter.includes(x.brand_id) && x
      }).filter(x => x !== false)
    }
    return { code, result: tmpResult }
  }
  return { code, result }
}
