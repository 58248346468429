import { types } from "@/types"

/* eslint-disable max-lines */
export const constants = {
  // Url's para los fetch
  URL: {
    ERP_DOMAIN: `/erp`,
    B2B_DOMAIN: `/b2b`,
    ROBOTS: `/robots`,
    INIT: `/platform/info/`,
    SEO: `/seo/`,
    IP_INFO: `/ip/info`,
    DEFAULT_SHIP: `/default-ship/`,
    MENU: `/menu/`,
    BANNER_HOME: `${process.env.NEXT_PUBLIC_HOME_VIEW}/banner/`,
    SECTIONS_HOME: `${process.env.NEXT_PUBLIC_HOME_VIEW}/section/`,
    BRANDS: `/brands/`,
    CATEGORIES: `/categories/`,
    LOGIN: `${process.env.NEXT_PUBLIC_USER}/logins`,
    REGISTER: `${process.env.NEXT_PUBLIC_USER}/register`,
    TRANSLATES: `/translates/`,
    COUNTRYS: `${process.env.NEXT_PUBLIC_POSTALCODE}/country/`,
    POSTAL_CODE: `${process.env.NEXT_PUBLIC_POSTALCODE}/get/`,
    DESTINATIONS: `${process.env.NEXT_PUBLIC_POSTALCODE}/destination/`,
    UPLOAD_FILES: `/upload-files`,
    RECOVER_PASS: `${process.env.NEXT_PUBLIC_RECOVER_PASSWORD}`,
    RECOVER_PASS_CHECK: `${process.env.NEXT_PUBLIC_RECOVER_PASSWORD}/check`,
    RECOVER_PASS_RESET: `${process.env.NEXT_PUBLIC_RECOVER_PASSWORD}/reset`,
    VARIANTS: `/variants/`,
    FILTERS: `/filters/`,
    FILTERS_SEARCH: `/filters-search/`,
    CATALOGO: `/catalog/`,
    ERROR: `/error`,
    VISIT_CATALOGUE: `/search/catalog/visit`,
    HOME: `/home/`,
    SEARCH: `/search/`,

    //* ------------------ PRODUCT -------------------
    PRODUCT: `/product`,
    PRODUCT_VISIT: `/product/visit`,
    PRODUCT_WISH: `/product/wish`,
    PRODUCT_NOTIFY: `/product/notify`,
    PRODUCT_CANONICAL: `/product/canonical`,
    PRODUCT_RATING: `/product/rating`,
    PRODUCT_GUIDES: `/product/size-guides`,
    PRODUCT_COLORS: `/product/colors`,
    SERVICES_COSTS: `/services-costs`,
    SHIP_COSTS_FAQ: `/shipcosts-faq`,

    //* --------------------------------------------
    //* ------------------ USER -------------------
    PROFILE_DEL_ACCOUNT: `${process.env.NEXT_PUBLIC_USER_PROFILE}/delete-account`,
    PROFILE_INFO: `${process.env.NEXT_PUBLIC_USER_PROFILE}/data`,
    PROFILE_INFO_SAVE: `${process.env.NEXT_PUBLIC_USER_PROFILE}/data/save`,
    PROFILE_PASSWORD: `${process.env.NEXT_PUBLIC_USER_PROFILE}/password`,
    PROFILE_ORDERS: `${process.env.NEXT_PUBLIC_USER_PROFILE}/orders/data`,
    PROFILE_ORDER_DETAIL: `${process.env.NEXT_PUBLIC_USER_PROFILE}/orders/detail`,
    PROFILE_CANCEL_ORDER: `${process.env.NEXT_PUBLIC_USER_PROFILE}/orders/cancel`,
    PROFILE_ORDER_PDF: `${process.env.NEXT_PUBLIC_USER_PROFILE}/orders/pdf`,
    PROFILE_ORDERS_LIST: `${process.env.NEXT_PUBLIC_USER_PROFILE}/orders/list`,
    PROFILE_ORDERS_TRACKING: `${process.env.NEXT_PUBLIC_USER_PROFILE}/orders/shipping_method/tracking`,
    PROFILE_PDF: `${process.env.NEXT_PUBLIC_USER_PROFILE}/pdf`,
    PROFILE_CONFIRM_PAY: `${process.env.NEXT_PUBLIC_CART}/recovery/`,
    PROFILE_WISHLIST: `${process.env.NEXT_PUBLIC_USER_PROFILE}/wish/list`,
    PROFILE_WISHLIST_DELETE: `${process.env.NEXT_PUBLIC_USER_PROFILE}/wish/delete`,
    ADDRESS_INFO: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/list`,
    ADDRESS_DEL: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/delete`,
    ADDRESS_UPD: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/update`,
    ADDRESS_CRE: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/create`,
    ADDRESS_SET: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/select-invoice`,
    ADDRESS_COUNTRIES: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/countries`,
    ADDRESS_COMPLETE: `${process.env.NEXT_PUBLIC_USER_PROFILE}${process.env.NEXT_PUBLIC_ADDRESS}/complete`,
    PROFILE_PAYMETHODS: `${process.env.NEXT_PUBLIC_USER_PROFILE}/payMethods`,
    PROFILE_COUPONS: `${process.env.NEXT_PUBLIC_USER_PROFILE}/coupons`,
    PROFILE_RATINGS: `${process.env.NEXT_PUBLIC_USER_PROFILE}/ratings`,
    PROFILE_NEWSLETTER_GET: `${process.env.NEXT_PUBLIC_USER_PROFILE}/newsletter/get`,
    PROFILE_NEWSLETTER_SET: `${process.env.NEXT_PUBLIC_USER_PROFILE}/newsletter/set`,
    PROFILE_TICKETS: `${process.env.NEXT_PUBLIC_USER_PROFILE}/tickets/view`,

    // PROFILE_TICKETS_SEND: `${process.env.NEXT_PUBLIC_USER_PROFILE}/tickets/send`,
    PROFILE_TICKETS_SEND: `${process.env.NEXT_PUBLIC_USER_PROFILE}/tickets/send-chat`,

    //* --------------------------------------------
    //* ------------------ TICKETS -------------------
    FIRST_CONTACT_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}`,
    CHECK_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/check`,
    OPEN_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/open`,
    OPTION_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/option`,
    OPTION_LIST_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/option/list`,
    OPTION_TRANSFER_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/option/transfer`,
    OPTIONS_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/options`,
    RESPONSES_TICKETS: `${process.env.NEXT_PUBLIC_TICKETS}/responses`,

    //* --------------------------------------------
    //* ------------------ CART -------------------
    CART_INIT: `${process.env.NEXT_PUBLIC_CART}/recovery`,
    CART_ADD_REMOVE: `${process.env.NEXT_PUBLIC_CART}/add`,
    CART_CONFIRM: `${process.env.NEXT_PUBLIC_CART}/confirm`,
    CART_DELETE: `${process.env.NEXT_PUBLIC_CART}/delete`,
    CART_SESSION: `${process.env.NEXT_PUBLIC_CART}/session`,
    CART_UPDATE_PAYMETHOD: `${process.env.NEXT_PUBLIC_CART}/update-paymethod`,
    CART_UPDATE_CURRENCY: `${process.env.NEXT_PUBLIC_CART}/update-currency`,
    CART_SHIP_COST: `${process.env.NEXT_PUBLIC_CART}/ship-cost`,
    CART_SHIP_INSURANCE: `${process.env.NEXT_PUBLIC_CART}/insurance`,
    CART_CALCULATE_COST: `${process.env.NEXT_PUBLIC_CART}/calculate-cost`,
    CART_VINCULE: `${process.env.NEXT_PUBLIC_CART}/vincule`,
    CART_PAYMETHODS: `${process.env.NEXT_PUBLIC_CART}${process.env.NEXT_PUBLIC_PAYMETHODS}/`,
    CART_COUPONS: `${process.env.NEXT_PUBLIC_CART}/coupons`,
    CART_COUPON_ACTION: `${process.env.NEXT_PUBLIC_CART}/coupons/action`,
    CART_DISCOUNT_CODE: `${process.env.NEXT_PUBLIC_CART}/discount-code`,
    CART_DISCOUNT_SERVICE: `${process.env.NEXT_PUBLIC_CART}/discount-service`,
    SHIP_COST: `${process.env.NEXT_PUBLIC_CART}/shipping_cost/`,
    SHIP_PICKUPS: `${process.env.NEXT_PUBLIC_CART}/ship-pickups`,
    ACC_PHONE: `${process.env.NEXT_PUBLIC_USER_PROFILE}/updatePhone`,
    ACC_GET_PHONE: `${process.env.NEXT_PUBLIC_USER_PROFILE}/getPhone`,

    //* --------------------------------------------
    REDIS_EXISTS: `/redis/exists`,
    TRACKING: `/shipping_method/tracking/`,
    TRANSLATES_SEARCH: `/search/translates/`,
    SYNONIMS: `/search/synonyms/`,
    SYNONIMS_ADD: `/search/synonyms/add/`,
    SYNONIMS_GET: `/search/list`,
    SYNONIMS_PRODUCT: `/search/products`,
    NEWSLETTER: `${process.env.NEXT_PUBLIC_USER}/newsletter`,
    METAMOTO: `/moto/model`,
    CAT_RELATIONS: `/list/category/relations`,
    TO_RECEIVE: `/date/to-recieve`,
    ESTIMATED_DATE: `/date/estimated`,
    STOCK_SHOPS: `/stock/availability`,
    RECAPTCHA: `/google/recaptcha`,
    TICKET_SUBJECTS: `/ticket-subjects`,
    MAIL_SEND: `/mail/send`,
    PAYPAL_CREATE_ORDER: `/paypal/verify-order`,
    ONEY_CREATE_ORDER: `/oney/get-url`,
    ONEY_INFO: `/oney/getOneyInfo`,
    ONEY_CHECK: `/oney/checkCountry`,
    ALLOWED_REVIEW: `/allowed-review`,
    UNRATED_PRODUCTS: `/unrated-products`,
    SAVE_REVIEW: `/save-review`,
    SAVE_INTERNAL_REVIEW: `/save-internal-review`,
    CHECK_INTERNAL_REVIEW: `/check-internal-review`,
    GET_PDRS: `/getPdrs`,
    CHECK_PDR: `/checkPdr`,
    INFO_PDR: `/getInfoPdr`,
    WEBDATA_GETMETAS: `/webdata/urls/getMetas`,
    MULTIBANCO_TRANSACTION: `/multibanco/create-transaction`,
    MULTIBANCO_CONFIRM: `/multibanco/pay-order`,
    PHONE_PREFIX: `/phone-prefix`,
    REVOLUT_GET_PAY_URL: `/revolut/payUrl`,
    REVOLUT_GET_API_KEY: `/revolut/apiKey`,

    //* ------------------ BIKES -------------------
    BIKE_INFO: `/bikes/info/false`,
    BIKE_CATALOG: `/bikes/catalog/`,
    BIKE_MAIL_SEND: `/bikes/mail/send`,
    MULTIBANCO_PENDING: `/multibanco/check`,
    BIKE_PUBLISH: `/bikes/publish`,
    BIKE_UPDATE: `/bikes/update`,
    BIKE_IMG_DEL: `/bikes/images/delete`,
    BIKE_SET_SOLD: `/bikes/set-sold`,
    BIKE_SET_RESERVED: `/bikes/set-reserved`,
    BIKE_UNPUB: `/bikes/set-pub`,

    // B2B
    BIKE_BRANDS: `/motovo/brands`,
    BIKE_MODELS: `/motovo/models`,
    BIKE_YEARS: `/motovo/years`,

    //* ------------------ THEMES -------------------
    GET_THEME: `/theme/get/`,

    //* --------------------------------------------
    //* ------------------ TEXTS -------------------
    GET_TEXTS: `/texts`,
  },

  //* --------------------------------------------

  // WIDTH para apertura de los menus laterales
  SWIPED_WIDTH: 50,

  // RegExp Para NIF/CIF
  /**
   * URL's para ver las condiciones de CIF/NIF de cada País:
   * https://marosavat.com/es/formatos-de-nif-en-europa/
   * http://www.validar-nif-iva.es/
   *
   * ES: X(-|\\.)?0?\\d{7}(-|\\.)?[A-Z]|[A-Z](-|\\.)?\\d{7}(-|\\.)?[0-9A-Z]|\\d{8}(-|\\.)?[A-Z]
   * FR: [0-3]\\d{12}?|[0-9A-Z][0-9A-Z]\\d{9}
   * DE: \\d{9}
   * IT: \\d{11}
   * PT: \\d{9}
   * UK: \\d{9}
   *
   */
  REG_EXP: `^(X(-|\\.)?0?\\d{7}(-|\\.)?[A-Z]|[A-Z](-|\\.)?\\d{7}(-|\\.)?[0-9A-Z]|\\d{8}(-|\\.)?[A-Z]?|[0-3]\\d{12}?|[0-9A-Z][0-9A-Z]\\d{9}?|\\d{9}?|\\d{11})$`,

  // RegExp Para PHONE
  /**
   * XXXXXXXXX | XXX-XXX-XXX   | XXX XXX XXX
   * XXXXXXXXX | XXX-XX-XX-XX  | XXX XX XX XX
   * XXXXXXXXX | X-XX-XX-XX-XX | X XX XX XX XX
   */
  REG_EXP_PHONE: `^[0-9\\s\\-+]+$`,

  // REG_EXP_PHONE: `^([1-9]\\d{2}[- | \\.]?\\d{3}[- | \\.]?\\d{3}|[1-9]\\d{2}[- | \\.]?\\d{2}[- | \\.]?\\d{2}[- | \\.]?\\d{2}|[1-9][- | \\.]?\\d{2}[- | \\.]?\\d{2}[- | \\.]?\\d{2}[- | \\.]?\\d{2}|[0-9]\\d{3}[- | \\.]?\\d{3}[- | \\.]?\\d{3}|[0-9]\\d{3}[- | \\.]?\\d{3}[- | \\.]?\\d{4})$`,

  // Tamaño maximo de archivos pdf para subir
  MAX_SIZE: 2097152,

  // ERROR INPUTS
  ERROR__INPUT: {
    errorUser: `errorUser`,
    errorPass: `errorPass`,
    errorCompany: `errorCompany`,
    errorContact: `errorContact`,
    errorWeb: `errorWeb`,
    errorNif: `errorNif`,
    errorPhone: `errorPhone`,
    errorEmail: `errorEmail`,
    errorAttach: `errorAttach`,
    errorStreet: `errorStreet`,
    errorNumber: `errorNumber`,
    errorFloor: `errorFloor`,
    errorExtra: `errorExtra`,
    errorComment: `errorComment`,
    errorReviewTitle: `errorReviewTitle`,
    errorReviewComment: `errorReviewComment`,
    errorReviewPros: `errorReviewPros`,
    errorReviewCons: `errorReviewCons`,
  },

  // Rango de precios
  PRICE_RANGE: {
    min: 0,
    max: 3500,
    diff: 100,
  },

  // Calculo para el rango de precios del carousel de ficha d eproducto
  RANGE_LIMIT: 20,

  // Limite de productos por catalogo
  CATALOGO_LIMIT: 24,

  // Limite de productos por catalogo
  SEARCH_LIMIT: 12,

  // configuración de BADGE
  BADGE: {
    max: 9,
    showZero: false,
  },

  MOMENT_FORMAT: {
    days: `DD`,
    month: `M`,
    annio: `YYYY`,
  },
  ICON_SIZE: 20,
  ICON_SIZE_MOBILE: 80,
  GRID_MENU: `auto`,

  DESKTOP_MENU: {
    TOP: 20,
    BOTTOM: 50,
    DIFF: 10,
    ICON_SIZE: 24,
  },

  MENU_HEIGHT: 90,
  DRAWER: 235,
  DRAWER_CALC: 185,
  FOOTER_HEIGHT: 400,

  INNER_WIDTH: 1199.98,

  NO_IMG: `/img/no-image.png`,
  IMG_CHECK: [ `shad`, `shopify` ],

  RATING: {
    path: `/img/product/icon-rating.svg`,
    name: `rating`,
  },

  OUTLET: {
    path: `/img/cards/sello-outlet.webp`,
    name: `outlet`,
    cat: `167`,
  },
  NEW: {
    name: `new`,
    cat: `166`,
  },

  BLACK_FRIDAY: {
    path: `/img/cards/sello-black-friday.webp`,
    name: `black-friday`,
    cat: `133`,
  },

  SUPPORT_ICONS: [ types.supportIcons.buy, types.supportIcons.order, types.supportIcons.received ],

  URI_LANG_WEB: {
    es: [`com`],
    en: [`uk`],
    de: [`net`],
    pt: [`pt`],
    fr: [`fr`],
    it: [`it`],
  },

  TAB: `&nbsp;&nbsp;&nbsp;&nbsp;`,

  CAORUSEL_BRAND: {
    navButtonsAlwaysInvisible: true,
    animation: `fade`,
    swipe: true,
    interval: 4000,
    duration: 750,
  },

  CAORUSEL_RELATIONS: {
    animation: `fade`,
    swipe: true,
    interval: 4000,
    duration: 750,
    fullHeightHover: false,
  },
  CAORUSEL_IMG: {
    animation: `fade`,
    swipe: true,
    interval: 5000,
    duration: 0,
    fullHeightHover: false,
    autoPlay: false,
    indicators: false,
  },

  NOTIFY_DISPONIBILITY: [types.input.email],

  FIELDS_AUTOC: [
    types.input.dir.country, types.input.dir.zip, types.input.dir.state, types.input.dir.district, types.input.dir.city, types.input.dir.town
  ],
  FIELDS_GOOGLE: [types.input.dir.street],
  FIELDS_CHECK: [ types.input.dir.default_invoice_address, types.input.dir.default_shipping_address ],
  BASKET_SUMARY: [
    `reference`, `estimated_delivery`, `address`, `shipping`, `payMethod`, `total_price`
  ],
  NOTREQ_FIELDS: [
    types.input.lastName,
    types.input.vatNumber,
    types.input.dir.name,
    types.input.dir.vatNumber,
    types.input.dir.comment,
    types.input.dir.floor,
    types.input.dir.extra,
    types.input.dir.city,
    types.input.dir.state,
    types.input.dir.default_invoice_address,
    types.input.dir.default_shipping_address,
    types.input.pros,
    types.input.cons,
  ],

  REPLACE_GT: [ `&gt;`, `>` ],
  DOMAINS: {
    1: `com`,
    2: `net`,
    3: `uk`,
    4: `fr`,
    5: `it`,
    6: `pt`,
  },

  LANG_HEIGHT: 195,
  POPPER_HEIGHT: 195,
  OBJ_FILTER: {
    type: null,
    parent: null,
    parentId: null,
    childId: null,
    page: 1,
    meta_moto: 0,
    brandName: ``,
    model_id: null,
    model: null,
    pathParams: [],
    price: {
      min: null,
      max: null,
    },
    element: null,
  },

  LINE: {
    basket: {
      wd: 300,
      ctr: 0.95,
    },
    cart: {
      wd: `auto`,
      ctr: 0.95,
    },
  },
  LOCATION: `ChIJ3fXcKkCCYw0RBBxn4Une97Y`,
  GRIDX: {
    SERVER: `server`,
    CLIENT: `client`,
  },
  VARIANT_AVAILABLE: [ 1, 2 ]
}
